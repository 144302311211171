.my-broker-wrapper {
  padding-top: 3rem;
  .broker-name {
    color: #010b1e;
  }
  .p-brokerage {
    color: #010b1e;
    font-size: 1rem;
    margin: 0;
  }
  .brokerage-details-wrapper {
    text-align: end;
    margin: auto 0 auto auto;
    @include sm {
      text-align: center;
      margin: auto;
    }
    .brokerage-header {
      color: #010b1e;
    }
    .header-type {
      color: #010b1e;
    }

    .brokerage-rating {
      margin-left: auto;
      color: #010b1e;
      border: 1px solid #7e7e7e;
      padding: 5px 15px;
      border-radius: 4px;
    }
  }
}
