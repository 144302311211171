.action-add-wrapper {
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
  .choice-group {
    margin-bottom: 25px;
    .choice-header {
      font-weight: bold;
      text-decoration: underline;
    }
    .choice-list {
      display: flex;
      margin-top: 15px;
      width: 100%;
      overflow: auto;
      padding-bottom: 5px;
      &::-webkit-scrollbar {
        height: 5px;
      }
      .choice-item {
        height: 90px;
        width: 90px;
        border: 1px solid #e7e7e7;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 10px;
        padding: 20px;
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        &.active {
          border: 2px solid #cccccc;
        }
        cursor: pointer;
        .choice-icon {
          width: 30px;
          height: 30px;
        }
        .choice-name {
          margin-top: 5px;
          font-size: 11px;
          text-align: center;
        }
      }
    }
  }
}

.hash-form-container {
  height: 0;
  flex-grow: 1;
  overflow-y: auto;
  .headCoin {
    display: flex;
    height: 60px;
    border: 1px solid $border-color;
    color: $primary-color;
    font-size: 24px;
    font-weight: 600;
    padding: 0 40px;
    align-items: center;
    img {
      height: 50%;
      margin-right: 15px;
    }
  }
  .steps {
    border: 1px solid $border-color;
    border-top: none;
    min-height: 300px;
    padding: 30px 20px;
    h5 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 15px;
      font-size: 12px;
    }
    .boxView {
      border: 1px solid $border-color;
      height: 40px;
      display: flex;
      margin-bottom: 25px;
      > span {
        height: 100%;
        width: 0;
        flex: 1;
        padding: 0;
        display: flex;
      }
      .ipVal {
        height: 100%;
        background: none;
        border: none;
        width: 0;
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0 20px;
      }
      .boxBtn {
        cursor: pointer;
        transition: 600ms ease all;
        border-left: 1px solid $border-color;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img,
        svg {
          height: 50%;
          width: 50%;
        }
        .qrClose {
          display: none;
        }
        &.true {
          padding: 40px;
          height: 300px;
          width: 300px;
          background: whitesmoke;
          border: none;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 20px;
          svg {
            width: 100%;
            height: 100%;
          }
          .qrClose {
            position: absolute;
            top: -12px;
            right: -12px;
            display: flex;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: white;
            border: 1px solid $border-color;
            transform: rotate(-45deg);
            &::before,
            &::after {
              content: '';
              position: absolute;
              width: 25px;
              height: 25px;
              background-color: $border-color;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
            &::before {
              width: 3px;
            }
            &::after {
              height: 3px;
            }
          }
        }
      }
    }
    .btnSubmit {
      margin-top: 25px;
      cursor: pointer;
      height: 50px;
      width: 200px;
      border: 2px solid $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
    }
    .buttonsList {
      display: flex;
      justify-content: space-between;
      .btnClose,
      .btnCopy,
      .btnUnCover {
        cursor: pointer;
        margin-top: 25px;
        cursor: pointer;
        height: 50px;
        border: 2px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
      }
      .btnClose,
      .btnCopy {
        flex: 0 0 22%;
      }
      .btnUnCover {
        flex: 0 0 50%;
      }
    }
  }
}
