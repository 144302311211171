.search-box {
  -webkit-transition: width 0.6s, border-radius 0.6s, background 0.6s,
    -webkit-box-shadow 0.6s;
  transition: width 0.6s, border-radius 0.6s, background 0.6s,
    -webkit-box-shadow 0.6s;
  -o-transition: width 0.6s, border-radius 0.6s, background 0.6s,
    box-shadow 0.6s;
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s,
    -webkit-box-shadow 0.6s;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  background: #ebebeb;
  .search-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    right: 42px;
    height: 15px;
  }
}

.search-box + label .search-icon {
  color: black;
}

.search-box:hover {
  color: white;
  background: #c8c8c8;
  -webkit-box-shadow: 0 0 0 5px #3d4752;
  box-shadow: 0 0 0 5px #3d4752;
}

.search-box:hover + label .search-icon {
  color: white;
}

.search-box:focus {
  -webkit-transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39),
    border-radius 0.6s, background 0.6s;
  -o-transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39),
    border-radius 0.6s, background 0.6s;
  transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s,
    background 0.6s;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 15px;
  cursor: text;
  width: 300px;
  border-radius: auto;
  background: #ebebeb;
  color: black;
}

.search-box:focus + label .search-icon {
  color: black;
}

.search-box:not(:focus) {
  text-indent: -5000px;
}

#search-submit {
  position: relative;
  left: -5000px;
}
