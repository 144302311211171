.bot-lineage {
  flex: 1;
  display: flex;
  flex-direction: column;
  .licenses-bots-wrapper {
    padding: 0 !important;
    border: none !important;
    margin-bottom: 0 !important;
  }
  .faq-container {
    .header {
      font-weight: bold;
    }
  }
  .option-list {
    display: flex;
    flex-direction: column;
  }
  .option-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $border-color;
    padding: 20px;
    margin-top: 20px;
    cursor: pointer;
    .option-icon {
      width: 35px;
      height: 35px;
    }
    .option-name {
      flex: 1;
      margin-left: 10px;
    }
  }
  .faq-form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    padding: 20px;
    margin-bottom: 20px;

    .option-item {
      margin-top: 0;
      margin-left: -20px;
      margin-right: -20px;
    }
    .input-filter {
      display: flex;
      margin-top: 30px;
      .filter-item {
        border: 0.5px solid #e7e7e7;
        padding: 5px 20px;
        margin-right: 10px;
        font-size: 12px;
        cursor: pointer;
        opacity: 0.6;
        &.active {
          font-weight: 600;
          opacity: 1;
        }
      }
    }
    .input-container {
      border: 0.25px solid #e7e7e7;
      padding: 15px;
      margin-top: 30px;
      display: flex;
      input {
        font-size: 13px;
        flex: 1;
      }
    }
    .submit-button {
      background-color: #08152d;
      color: #fff;
      padding: 10px 35px;
      margin-right: auto;
      margin-top: 30px;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .list {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
  }
  .list-title {
    font-weight: 600;
    font-size: 13px;
    margin-top: 25px;
  }

  .loading-container {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
.user-container {
  display: flex;
  align-items: center;
  border: 0.25px solid #e7e7e7;
  padding: 10px 20px;
  margin-top: 10px;
  .user-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    background-color: gray;
  }
  .user-details {
    flex: 1;
    margin-left: 10px;
    .user-name {
      font-weight: 600;
      text-transform: capitalize;
    }
    .user-email {
      font-size: 12px;
    }
  }
  .user-dd {
    text-align: end;
    .dd-level {
      font-weight: 600;
    }
    .dd-level-label {
      font-size: 12px;
    }
  }
}

.view-header-container {
  background: #f1f4f6;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  .header {
    flex: 1;
    font-weight: 600;
  }
  .header-action {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-left: 20px;
  }
}
