.bot-options {
  padding: 20px 30px;
  border-bottom: 1px solid #e7e7e7;
  .main-category {
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 0;
    }
    .category-name {
      cursor: pointer;
      border: 1px solid #e7e7e7;
      flex: 0 0 28%;
      margin-right: 8%;
      font-size: 12px;
      display: flex;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      text-align: center;
      &.active {
        border: 3px solid #e7e7e7;
      }
    }
  }
  .sub-category {
    display: flex;
    overflow: auto;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    &::-webkit-scrollbar {
      height: 5px;
    }
    .category-item {
      cursor: pointer;
      flex: 0 0 23%;
      margin-right: 2.66%;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.active {
        border: 3px solid #e7e7e7;
      }
      .category-icon {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
      .category-name {
        font-weight: 500;
        font-size: 10px;
        text-align: center;
      }
    }
  }
}

.chatLearn {
  border-top: 1px solid $border-color;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  &.true {
    border-top: none;
  }
}

.chatLearn {
  z-index: 2;
  background: white;
  flex-direction: column;
  .coinHead {
    cursor: pointer;
    margin: 20px 40px 0px 40px;
    border: 1px solid $border-color;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 80px;
      width: 80px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        font-size: 16px;
      }
    }
  }
  .bondsItemHead {
    margin: 10px 40px;
    cursor: pointer;
    border: 1px solid $vault-border-color;
    padding: 3% 4%;
    display: flex;
    flex-direction: column;
    .coinPrice,
    .labels,
    .rates {
      display: flex;
      justify-content: space-between;
      color: $primary-color;
    }
    .coinPrice {
      .img {
        height: 44px;
        display: flex;
        align-items: center;
        font-size: 23px;
        font-weight: bold;
        img {
          height: 80%;
          margin-right: 10px;
        }
      }
      .bondTitle {
        font-size: 23px;
        font-weight: bold;
      }
    }
    .labels {
      font-size: 14px;
    }
    .rates {
      margin-top: 15px;
      .ratesItem {
        .value {
          font-size: 21px;
          font-weight: bold;
        }
        .label {
          font-size: 14px;
        }
      }
    }
    &:hover {
      background: #00000006;
    }
  }
  .whatToDo {
    margin-top: 10px;
    border-top: 1px solid $border-color;
    flex: 1;
    padding: 20px 40px;
    .whatDoTitle {
      font-size: 22px;
      font-weight: 600;
      text-decoration: underline;
    }
    .appList {
      margin-top: 20px;
      height: 120px !important;
      > div {
        display: flex;
        .app {
          height: 65px;
          width: 65px;
          flex: 0 0 65px;
          display: flex;
          border-radius: 4px;
          margin-right: 20px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .label {
            position: absolute;
            display: none;
            top: 105%;
            width: 100%;
            text-align: center;
            justify-content: center;
          }
          &:hover {
            .label {
              display: flex;
            }
          }
        }
      }
    }
    .actions {
      margin-top: 20px;
      height: 50px !important;
      > div {
        display: flex;
        align-items: center;
        .btnAction {
          cursor: pointer;
          flex: 0 0 160px;
          height: 85%;
          border: 1px solid $border-color;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
    }
  }
  .pubHead {
    margin: 24px 40px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    .seeAll {
      font-size: 17px;
      text-decoration: none;
    }
  }
  .categoryList {
    .categoryItm {
      cursor: pointer;
      margin: 0 40px 15px 40px;
      border: 1px solid $border-color;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 0 10px;
      .thumb {
        height: 80px;
        width: 80px;
      }
      .textContent {
        padding: 10px 15px;
        .pubTitle {
          font-size: 22px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .pubDesc {
          font-size: 16px;
        }
      }
    }
    .bondsItem {
      cursor: pointer;
      border: 1px solid $vault-border-color;
      margin: 20px 40px;
      padding: 3% 4%;
      display: flex;
      flex-direction: column;
      .coinPrice,
      .labels,
      .rates {
        display: flex;
        justify-content: space-between;
        color: $primary-color;
      }
      .coinPrice {
        .img {
          height: 44px;
          display: flex;
          align-items: center;
          font-size: 23px;
          font-weight: bold;
          img {
            height: 80%;
            margin-right: 10px;
          }
        }
        .bondTitle {
          font-size: 23px;
          font-weight: bold;
        }
      }
      .labels {
        font-size: 14px;
      }
      .rates {
        margin-top: 15px;
        .ratesItem {
          .value {
            font-size: 21px;
            font-weight: bold;
          }
          .label {
            font-size: 14px;
          }
        }
      }
      &:hover {
        background: #00000006;
      }
    }
  }
  .categoryListDetail {
    margin: 10px;
    width: unset !important;
    border: 1px solid $border-color;
    .categoryItm {
      position: sticky;
      top: 10px;
      cursor: pointer;
      margin: 10px;
      margin-bottom: 0;
      border: 1px solid $border-color;
      border-bottom: none;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 0 10px;
      z-index: 2;
      background: white;
      .thumb {
        height: 40px;
        width: 40px;
      }
      .textContent {
        padding: 10px 15px;
        .pubTitle {
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .pubDesc {
          font-size: 15px;
        }
      }
    }
    .itmDetail {
      margin: 0 10px;
      padding: 0 15px;
      border: 1px $border-color;
      border-style: none solid;
      .listItem {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        .key {
          width: 0;
          flex: 0 0 40%;
        }
        .value {
          width: 0;
          flex: 0 0 55%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .btnsDispute {
      display: flex;
      height: 60px;
      margin: 10px;
      margin-top: 0;
      bottom: 10px;
      position: sticky;
      background: white;
      .btnItm {
        border: 1px solid $border-color;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .headCategory {
    margin: 10px;
    border: 1px solid $border-color;
    display: flex;
    flex: 0 0 80px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 60px;
      width: 60px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        line-height: 1;
        font-size: 14px;
      }
    }
  }
  .contentType {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .contentTypeLabel {
      font-size: 14px;
      font-weight: 600;
    }
    .contentBtns {
      height: 40px;
      display: flex;
      justify-content: space-between;
      .btnCntntType {
        cursor: pointer;
        margin-top: 8px;
        border: 1px solid $border-color;
        flex: 0 0 49%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        .cntntIcon {
          height: 17px;
          width: 17px;
          margin-right: 10px;
        }
        &.true {
          border-color: $primary-color;
        }
      }
    }
  }
  .total {
    margin: 15px 10px 15px 10px;
    font-weight: 600;
    font-size: 14px;
  }
  .categoryListVideo {
    margin: 10px 10px 25px 15px;
    width: unset !important;
    height: 0 !important;
    flex: 1;
    > div {
      display: flex;
      padding-bottom: 4px;
      .videoCard {
        border: 1px solid $border-color;
        flex: 0 0 320px;
        margin-right: 50px;
        .videoHolder {
          width: 100%;
          height: fit-content;
          position: relative;
          .videoHolderImg {
            width: 100%;
            height: auto;
          }
          .reactPlayer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
        .vidTitle {
          margin: 10px;
          font-size: 19px;
          font-weight: 600;
        }
        .desc {
          margin: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

.chatLearn {
  border-top: 1px solid $border-color;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  &.true {
    border-top: none;
  }
}

.chatLearn {
  z-index: 2;
  background: white;
  flex-direction: column;
  .coinHead {
    cursor: pointer;
    margin: 20px 40px 0px 40px;
    border: 1px solid $border-color;
    display: flex;
    height: 100px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 80px;
      width: 80px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        font-size: 16px;
      }
    }
  }
  .bondsItemHead {
    margin: 10px 40px;
    cursor: pointer;
    border: 1px solid $vault-border-color;
    padding: 3% 4%;
    display: flex;
    flex-direction: column;
    .coinPrice,
    .labels,
    .rates {
      display: flex;
      justify-content: space-between;
      color: $primary-color;
    }
    .coinPrice {
      .img {
        height: 44px;
        display: flex;
        align-items: center;
        font-size: 23px;
        font-weight: bold;
        img {
          height: 80%;
          margin-right: 10px;
        }
      }
      .bondTitle {
        font-size: 23px;
        font-weight: bold;
      }
    }
    .labels {
      font-size: 14px;
    }
    .rates {
      margin-top: 15px;
      .ratesItem {
        .value {
          font-size: 21px;
          font-weight: bold;
        }
        .label {
          font-size: 14px;
        }
      }
    }
    &:hover {
      background: #00000006;
    }
  }
  .whatToDo {
    margin-top: 10px;
    border-top: 1px solid $border-color;
    flex: 1;
    padding: 20px 40px;
    .whatDoTitle {
      font-size: 22px;
      font-weight: 600;
      text-decoration: underline;
    }
    .appList {
      margin-top: 20px;
      height: 120px !important;
      > div {
        display: flex;
        .app {
          height: 65px;
          width: 65px;
          flex: 0 0 65px;
          display: flex;
          border-radius: 4px;
          margin-right: 20px;
          position: relative;
          img {
            height: 100%;
            width: 100%;
          }
          .label {
            position: absolute;
            display: none;
            top: 105%;
            width: 100%;
            text-align: center;
            justify-content: center;
          }
          &:hover {
            .label {
              display: flex;
            }
          }
        }
      }
    }
    .actions {
      margin-top: 20px;
      height: 50px !important;
      > div {
        display: flex;
        align-items: center;
        .btnAction {
          cursor: pointer;
          flex: 0 0 160px;
          height: 85%;
          border: 1px solid $border-color;
          margin-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
    }
  }
  .pubHead {
    margin: 24px 40px;
    font-weight: 500;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 21px;
    .seeAll {
      font-size: 17px;
      text-decoration: none;
    }
  }
  .categoryList {
    .categoryItm {
      cursor: pointer;
      margin: 0 40px 15px 40px;
      border: 1px solid $border-color;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 0 10px;
      .thumb {
        height: 80px;
        width: 80px;
      }
      .textContent {
        padding: 10px 15px;
        .pubTitle {
          font-size: 22px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .pubDesc {
          font-size: 16px;
        }
      }
    }
    .bondsItem {
      cursor: pointer;
      border: 1px solid $vault-border-color;
      margin: 20px 40px;
      padding: 3% 4%;
      display: flex;
      flex-direction: column;
      .coinPrice,
      .labels,
      .rates {
        display: flex;
        justify-content: space-between;
        color: $primary-color;
      }
      .coinPrice {
        .img {
          height: 44px;
          display: flex;
          align-items: center;
          font-size: 23px;
          font-weight: bold;
          img {
            height: 80%;
            margin-right: 10px;
          }
        }
        .bondTitle {
          font-size: 23px;
          font-weight: bold;
        }
      }
      .labels {
        font-size: 14px;
      }
      .rates {
        margin-top: 15px;
        .ratesItem {
          .value {
            font-size: 21px;
            font-weight: bold;
          }
          .label {
            font-size: 14px;
          }
        }
      }
      &:hover {
        background: #00000006;
      }
    }
  }
  .categoryListDetail {
    margin: 10px;
    width: unset !important;
    border: 1px solid $border-color;
    .categoryItm {
      position: sticky;
      top: 10px;
      cursor: pointer;
      margin: 10px;
      margin-bottom: 0;
      border: 1px solid $border-color;
      border-bottom: none;
      display: flex;
      height: 100px;
      align-items: center;
      padding: 0 10px;
      z-index: 2;
      background: white;
      .thumb {
        height: 40px;
        width: 40px;
      }
      .textContent {
        padding: 10px 15px;
        .pubTitle {
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }
        .pubDesc {
          font-size: 15px;
        }
      }
    }
    .itmDetail {
      margin: 0 10px;
      padding: 0 15px;
      border: 1px $border-color;
      border-style: none solid;
      .listItem {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
        .key {
          width: 0;
          flex: 0 0 40%;
        }
        .value {
          width: 0;
          flex: 0 0 55%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .btnsDispute {
      display: flex;
      height: 60px;
      margin: 10px;
      margin-top: 0;
      bottom: 10px;
      position: sticky;
      background: white;
      .btnItm {
        border: 1px solid $border-color;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .headCategory {
    margin: 10px;
    border: 1px solid $border-color;
    display: flex;
    flex: 0 0 80px;
    align-items: center;
    padding: 0 10px;
    .thumb {
      height: 60px;
      width: 60px;
    }
    .textContent {
      padding: 10px 15px;
      .pubTitle {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .pubDesc {
        line-height: 1;
        font-size: 14px;
      }
    }
  }
  .contentType {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    .contentTypeLabel {
      font-size: 14px;
      font-weight: 600;
    }
    .contentBtns {
      height: 40px;
      display: flex;
      justify-content: space-between;
      .btnCntntType {
        cursor: pointer;
        margin-top: 8px;
        border: 1px solid $border-color;
        flex: 0 0 49%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        .cntntIcon {
          height: 17px;
          width: 17px;
          margin-right: 10px;
        }
        &.true {
          border-color: $primary-color;
        }
      }
    }
  }
  .total {
    margin: 15px 10px 15px 10px;
    font-weight: 600;
    font-size: 14px;
  }
  .categoryListVideo {
    margin: 10px 10px 25px 15px;
    width: unset !important;
    height: 0 !important;
    flex: 1;
    > div {
      display: flex;
      padding-bottom: 4px;
      .videoCard {
        border: 1px solid $border-color;
        flex: 0 0 320px;
        margin-right: 50px;
        .videoHolder {
          width: 100%;
          height: fit-content;
          position: relative;
          .videoHolderImg {
            width: 100%;
            height: auto;
          }
          .reactPlayer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
        .vidTitle {
          margin: 10px;
          font-size: 19px;
          font-weight: 600;
        }
        .desc {
          margin: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

.overlayUncover,
.chatLearn {
  border-top: 1px solid $border-color;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  &.true {
    border-top: none;
  }
}

.overlayUncover {
  border: none;
  background-color: hsla(0, 0%, 100%, 0.9);
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-decoration: underline;
}

.bot-footer-container {
  background-color: $primary-color;
  display: flex;
  height: 55px;
  .footer-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      .footer-icon {
        transform: scale(1.1);
        filter: drop-shadow(0 0 0.5rem white);
      }
    }
    .footer-icon {
      height: 20px;
      width: 20px;
      transition: all 0.3s ease;
      &.active {
        filter: drop-shadow(0 0 0.5rem white);
        transform: scale(1.4);
      }
    }
  }
  .menu-button {
    width: 65px;
    height: 55px;
    position: relative;
    cursor: pointer;
    .menu-icon {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 65px;
      width: 65px;
      border-radius: 50px;
      background: #fff;
      padding: 12px;
      border: 1px solid #464b4e;
    }
  }
}

.chats-io-menu-wrapper {
  padding: 20px;
  .menu-item {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    padding: 20px 30px;
    margin-top: 10px;
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
    }
    .menu-icon {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
    .menu-name {
    }
  }
}
