
.user-selected-view-wrapper {
  flex: 1;
  display: flex;
}
.rightSideCustom{
  width: 40%;
  padding-left: 3%;
  height: 100vh;padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .commingSoon{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 43px;

text-align: center;

color: #18191D;
  }
}
.bottNetworkTab{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 63px;
  position: absolute;
  z-index: 8;
  width: 41%;
  right: 0;
  bottom: 0px;
  border-top: 0.5px solid #dddd;
  border-right: 0.5px solid #dddd;
}
.alingskeleton{
  width: 40px !important;
  height: 10px !important;
  border-radius: 0px !important;
  margin-top: 10px;
}
  .user-carousel-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .carousel-container {
      width:auto !important;
      // height: 100%;
      .slick-list {
        // height: 100% !important;
        .slick-track {
          will-change: transform;
        }
        .slick-slide {
          & > div {
          }
        }
      }
    }
    .user-carousel-item-wrapper {
      .user-carousel-item-container {
      }
    }
    .user-carousel-item {
      display: flex;
      align-items: center;
      height: 17.5vh !important;
      cursor: pointer;
      padding-left: 0px !important;
      &:hover {
        .user-img {
          opacity: 1;
        }
        .user-details {
          .user-name {
            opacity: 1;
          }
          .user-email {
            opacity: 0.5;
          }
        }
      }

      &.active {
        .user-img {
          width: 110px !important;
          height: 110px !important;
          opacity: 1;
        }
        .user-details {
          .user-name {
            opacity: 1;
            font-size: 45px;
            white-space: nowrap;
          }
          .user-email {
            opacity: 0.5;
            font-size: 18px;
          }
        }
      }
      .user-img {
        width: 70px !important;
        height: 70px !important;
        opacity: 0.5;
        transition: all 500ms ease-in-out;
      }
      .user-details {
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        .user-name {
          font-weight: 700;
          font-size: 28px;
          opacity: 0;
          transition: all 200ms ease-in-out;
          text-transform: capitalize;
        }
        .user-email {
          opacity: 0;
          transition: all 200ms ease-in-out;
          font-size: 13px;
        }
      }
    }
  }

  
  .user-stats-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #dddd;
    overflow: hidden;
    .stats-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dddd;
      width: 280px;
      // @include hover-zoom(1.1);
      &.active {
        opacity: 1;
        transform: none;
      }
      &.not-active {
        opacity: 0.6;
      }
      .stats-value {
        text-align: center;
        font-weight: 700;
        font-size: 2.5rem;
      }
      .stats-label {
        text-align: center;
        color: rgba(8, 21, 45, 0.5);
        font-size: 0.8rem;
      }
    }
  }
  .user-stats-overview {
  }


.customsearchlayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overflow: hidden;
  padding-top: 69px;
  padding-left: 5%;
  padding-right: 5%;
  .user-carousel-item-wrapper{
    width: auto !important;
  }
  
.leftside{
  width: 45%;
  padding-top: 40px;
  overflow: scroll;
  height: 100vh;
  padding-bottom: 36px;
  padding-right: 6rem;
}
    .ImageSectionNetworkTab{
        display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    height: calc(100vh - 371px);
    }
  .inntercards {
    width: calc(100% / 2 - 50px) ; 
    margin-bottom: 2rem;
    &:nth-child(3n){
      width: 98px;
    
    }
    .ImageTextEmail {
      width: 98px;
      height: 98px;
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ddd;
      font-size: 34px;
      font-weight: 500;
      color: #08152d;
    }
    p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      width: 98px;
      white-space: pre-wrap;
      text-align: center;
      margin-bottom: 0;
      color: #08152d;
      display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.4rem;
    word-break: break-all;
    }
  }
  .TextAlingCenterSearch{
    width: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .rightSide {
    width: 40%;
    padding-left: 3%;
    height: 100vh;padding-top: 30px;



  }
  .inputSectionSearch {
    background: #ffffff;
    border: 0.5px solid rgba(24, 25, 29, 0.16);
    border-radius: 25px 25px;
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    height: 68px;
    color: #18191d;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .LeftSideSectionInput {
    border-left: 0.5px solid rgba(24, 25, 29, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 100%;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    display: flex;
    align-items: center;

    color: #18191d;
    margin-bottom: 5px;
    margin-top: 3rem;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: rgba(8, 21, 45, 0.5);
  }
  input {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #18191d;
    width: 100%;
    padding-left: 2vw;
    opacity: 0.5;
  }
}

.listCustomereSection{
  height: 100vh;
  border-left: 0.5px solid #ddd;
  border-right: 0.5px solid #ddd;
  width: 15%;
  padding-bottom: 35px;
  padding-top: 30px;
  .InnerNamelist{
    height: 25%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.5px  solid #ddd;
    border-top: 0.5px  solid #ddd;
    justify-content: center;
    position: relative;
  }
  .Namevalue{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 49px;

text-align: center;

color: #18191D;
margin-bottom: 0.5rem;
  }
  .NameTextList{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
width: 100%;
text-align: center;

color: #18191D;
img{
  margin-right: 10px;
  position: absolute;
  left: -15px;
  top:44%
}
  }
}