.mobile-apps-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .close-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    .close-icon {
      width: 26px;
      height: 26px;
    }
  }
  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track {
        height: 100%;
      }
    }
  }
  .page-item {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    position: relative;

    .app-logo {
    }
    .actions-container {
      width: 200px;
      display: flex;
      flex-direction: column;
      margin-top: 5rem;
      .login-button {
        border-width: 1px;
        border-style: solid;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
      }
      .divider {
        margin: 20px 0;
        height: 1px;
      }
      .register-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
  .slick-dots {
    position: absolute !important;
    bottom: 20px;
  }
}
