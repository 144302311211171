.endorsement-home-wrapper {
  width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: $primary-color;
  .cover-img {
    background-image: url(../images/endorsement-cover.jpg);
    height: 350px;
    background-position: top -50px center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -60px;
    padding: 0 100px;
    .header-action {
      background-color: white;
      border: 1px solid $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 50px;
      font-weight: 600;
      text-align: center;
      font-size: 15px;
      color: $primary-color;
      @include hover-zoom(1.1);
    }
    .header-logo-container {
      height: 120px;
      padding: 0 50px;
      background-color: white;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      .header-logo {
        height: 40px;
      }
    }
  }
  .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    justify-content: space-evenly;
    .content-title {
      text-align: center;
      font-size: 17px;
    }
    .cards-container {
      display: flex;
      .card-item-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid $border-color;
        margin-right: 80px;
        &:last-child {
          margin-right: 0;
        }
        .card-item-header {
          border-bottom: 1px solid $border-color;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          padding: 20px 0;
          &.alt {
            background-color: $primary-color;
            color: white;
          }
        }
        .card-item-body {
          display: flex;
          flex-direction: column;
          padding: 40px 30px;
          height: 250px;
          justify-content: space-between;
          &.btn-list {
            padding: 20px 30px;
          }
          .card-text {
            text-align: center;
            font-weight: 400;
            font-size: 13px;
          }
          .card-action {
            text-align: center;
            border: 1px solid $border-color;
            font-weight: 600;
            font-size: 15px;
            padding: 15px;
            &.dark {
              color: white;
              background-color: $primary-color;
            }
            @include hover-zoom(1.05);
          }
          .body-btn-item {
            display: flex;
            align-items: center;
            border: 1px solid $border-color;
            padding: 15px;
            @include hover-zoom(1.05);
            .btn-icon {
              margin-right: 10px;
              width: 22px;
              height: 22px;
            }
            .btn-text {
              font-weight: 700;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
