.nav-tab-bar-wrapper {
  padding: 0 !important;
  .tab-container {
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
    .tab-empty-title {
      color: #010b1e;
      white-space: nowrap;
      margin-bottom: 0;
      align-self: center;
      span {
        margin-left: 10px;
        svg {
          height: 20px;
          width: 30px;
        }
      }
    }
    .tab-item-wrapper {
      display: flex;
      padding-left: 20px;
      padding-right: 30px;
      background: white;
      position: relative;
      cursor: pointer;
      min-width: 250px;
      max-width: 350px;
      &.active {
        background: white;
        border: 1px solid #041125;
        border-bottom: none;
      }
      &.dragging {
        z-index: 10;
      }
      .tab-details {
        display: flex;
        width: 100%;
      }
      .close-btn {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 9px;
        height: 9px;
        cursor: pointer;
        z-index: 1;
      }
      .tab-icon {
        width: 15px;
        height: 15px;
        align-self: center;
      }
      .suggestion-title {
        color: #010b1e;
        margin-left: 10px;
        margin-bottom: 0;
        flex-grow: 1;
        align-self: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .suggestion-title-count {
        color: #d6d6d6;
        margin-bottom: 0;
        align-self: center;
      }
    }
  }
  .tab-search-wrapper {
    display: flex;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    border-left: 1px solid #3b4c64;
    position: relative;
    margin-right: 30px;
    .search-input {
      background: transparent;
      border: none;
      outline: none;
      color: #010b1e;
      padding-left: 25px;
      padding-right: 15px;
      height: 100%;
      width: 220px;
    }
    .search-icon {
    }
    .search-suggestion-wrapper {
      position: absolute;
      top: 49px;
      width: 270px;
      z-index: 1;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: -1px;
      border: 1px solid #3b4c64;
      transition: all 300ms ease-in-out;
      .suggestion-item {
        display: flex;
        padding: 15px 25px;
        cursor: pointer;
        &:nth-child(odd) {
          background: #1a2535;
        }
        &:nth-child(even) {
          background: #041127;
        }
        .suggestion-title {
          color: #d6d6d6;
          margin-left: 10px;
          margin-bottom: 0;
          width: 220px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .suggestion-title-count {
          color: #d6d6d6;
          margin-bottom: 0;
        }
      }
    }
  }

  .saved-dashboards-wrapper {
    position: relative;
    display: flex;
    border-left: 1px solid #3b4c64;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 2;
    cursor: pointer;
    &:hover {
      .saved-dashboard-list {
        display: block;
      }
    }
    .save-menu-icon {
      margin-top: auto;
      margin-bottom: auto;
    }
    .saved-dashboard-list {
      position: absolute;
      top: 50px;
      right: 0;
      border: 1px solid #3b4c64;
      transition: all 300ms ease-in-out;
      margin-right: -1px;
      z-index: 3;
      display: none;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        margin-top: -15px;
        right: 0;
        transform: rotate(90deg);
        border: solid 8px transparent;
        border-right-color: rgba(255, 255, 255, 0.1);
        z-index: 1;
      }
      .saved-dashboard {
        display: flex;
        padding: 15px 25px;
        cursor: pointer;
        &:nth-child(odd) {
          background: #1a2535;
        }
        &:nth-child(even) {
          background: #041127;
        }
        .title {
          color: #d6d6d6;
          font-weight: bold;
          white-space: nowrap;
          margin-bottom: 0;
        }
        .saved-title {
          color: #d6d6d6;
          margin-left: 10px;
          margin-bottom: 0;
        }
        .eye-icon {
        }
      }
    }
  }

  .save-dashboard-wrapper {
    position: relative;
    display: flex;
    border-left: 1px solid #3b4c64;
    padding-left: 12px;
    padding-right: 12px;
    z-index: 2;
    cursor: pointer;
    &:hover {
      .save-action-wrapper {
        display: block;
      }
    }
    .save-action-wrapper {
      position: absolute;
      top: 50px;
      right: 0;
      border: 1px solid #3b4c64;
      transition: all 300ms ease-in-out;
      margin-right: -1px;
      z-index: 3;
      display: none;
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        margin-top: -15px;
        right: 0;
        transform: rotate(90deg);
        border: solid 8px transparent;
        border-right-color: rgba(255, 255, 255, 0.1);
        z-index: 1;
      }
      .save-header {
        padding: 15px 25px;
        background: #1a2535;
        .title {
          color: #d6d6d6;
        }
      }
      .save-form-wrapper {
        padding: 15px 25px;
        display: flex;
        background: #041127;
        .save-name-input {
          margin-right: 8px;
          border: 0.5px solid rgba(214, 214, 214, 0.5);
          background: transparent;
          outline: none;
          color: #010b1e;
          padding: 2px 10px;
        }
        .save-icon {
        }
      }
    }
  }
}
