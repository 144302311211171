.checkout-container {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  .header-container {
    background-color: #08152d;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .header-logo {
      height: 25px;
      width: 200px;
    }
    .close-button {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 15px;
      cursor: pointer;
    }
  }
  .body-container {
    padding: 30px;
    color: #08152d;
    display: flex;
    flex-direction: column;
    flex: 1;
    .title {
      font-weight: 700;
      font-size: 1.7rem;
    }
    .body {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 30px -30px;
      padding: 0 30px;
    }
    .option-list {
      display: flex;
      flex-direction: column;
      .option-item {
        display: flex;
        align-items: center;
        border: 1px solid #ebebeb;
        padding: 15px 20px;
        margin-bottom: 10px;
        cursor: pointer;
        .option-icon {
          width: 22px;
          height: 22px;
          margin-right: 15px;
        }
        .action-name {
          font-size: 0.9rem;
        }
      }
    }
    .sub-header {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .action-button {
      background-color: #08152d;
      color: white;
      font-weight: bold;
      border-radius: 6px;
      text-align: center;
      font-size: 1rem;
      padding: 10px;
      cursor: pointer;
    }
    .balance-view {
      align-items: center;
      border: 1px solid #ebebeb;
      padding: 10px 15px;
    }
    .complete-item {
      border: 1px solid #ebebeb;
      display: flex;
      margin-bottom: 15px;
      .item-title {
        border-right: 1px solid #ebebeb;
        flex: 1;
        text-align: center;
        font-weight: 600;
        padding: 15px 0;
      }
      .item-value {
        flex: 1;
        text-align: center;
        font-weight: 600;
        padding: 15px 0;
      }
    }
  }
  .all-license-list {
    height: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
}
