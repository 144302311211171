.crm-empty-wrapper {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-evenly;
  width: 100%;
  .empty-action {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 60px;
      background: #010b1e;
    }
    .action-title {
      cursor: pointer;
      color: #010b1e;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
