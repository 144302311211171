.broker-app-cover-wrapper {
  position: relative;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  zoom: 0.85;
  height: 280px;
  @include xxl {
    zoom: 1;
  }
  .cover-header {
    display: flex;
    justify-content: space-between;
    .menu-toggle {
      width: 30px;
      height: 30px;
      background-size: cover;
      // cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 3;
    }
    .header-wrapper {
      flex-grow: 1;
      .broker-name {
        color: #eeeeee;
        margin-bottom: 0;
      }
      .brokerage-assets {
        text-align: end;
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;
        transition: visibility 0s, opacity 400ms ease-in-out;
        &.show {
          visibility: visible;
          opacity: 1;
          height: auto;
          width: auto;
        }
        @include sm {
          text-align: center;
        }
        .assets-title {
          color: #d6d6d6;
          font-weight: 500;
          text-align: end;
          span {
            cursor: pointer;
            font-weight: lighter;
          }
        }
        .assets-value {
          color: #d6d6d6;
          font-weight: bold;
          margin-bottom: 0;
        }
      }
      .brokerage-assets-closed-menu {
        text-align: center;
        visibility: hidden;
        opacity: 0;
        height: 0;
        width: 0;
        transition: visibility 0s, opacity 400ms ease-in-out;
        position: relative;
        &.show {
          visibility: visible;
          opacity: 1;
          height: auto;
          width: auto;
        }
        .assets-title {
          color: #d6d6d6;
          font-weight: 500;
          margin-top: 0.5em;
          span {
            cursor: pointer;
            font-weight: lighter;
          }
        }
        .assets-value {
          color: #d6d6d6;
          font-weight: bold;
          margin-bottom: 0;
        }
        .chart-action-btn {
          background-size: cover;
          height: 26px;
          width: 26px;
          cursor: pointer;
          z-index: 1;
        }
        .click-overlay {
          position: absolute;
          height: 100vh;
          width: 100vw;
          z-index: 2;
        }
        .stats-controller-wrapper {
          position: absolute;
          display: none;
          right: 0;
          top: 35px;
          background: #010b1e;
          border: 1px solid #474654;
          padding-top: 15px;
          z-index: 3;
          transition: display 300ms ease-in-out;
          // user-select: none;
          &.show {
            display: block;
          }
          &::before {
            content: '';
            position: absolute;
            top: -4px;
            margin-top: -15px;
            right: 0;
            transform: rotate(90deg);
            border: solid 12px transparent;
            border-right-color: #010b1e;
            z-index: 1;
          }
          .controller-header-wrapper {
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            .controller-header {
              color: white;
              margin-bottom: 0;
            }
            .full-screen-btn {
              background-image: url(../../assets/images/full-screen-icon.svg);
              background-size: cover;
              height: 20px;
              width: 20px;
            }
          }
          .chart-controller-wrapper {
            display: flex;
            padding: 4px 15px;
            margin-bottom: 15px;
            border-top: 1px solid #474654;
            border-bottom: 1px solid #474654;
            .chart-type-controller {
              display: flex;
              flex-grow: 1;
              border-right: 2px solid #474654;
            }
            .chart-theme-controller {
              display: flex;
              flex-grow: 1;
            }
            .controller-item {
              color: #8b8c99;
              flex-grow: 1;
              font-weight: 300;
              cursor: pointer;
              &.active {
                color: white;
                font-weight: normal;
              }
            }
          }
          .search-wrapper {
            margin: 0 15px;
            display: flex;
            background: rgba(71, 70, 84, 0.3);
            border: 1px solid #474654;
            padding: 4px;
            border-radius: 4px;
            .search-icon {
              background-image: url(../../assets/images/search-light-icon.svg);
              height: 20px;
              width: 20px;
              background-size: cover;
              margin-top: auto;
              margin-bottom: auto;
            }
            .search-input {
              background: none;
              outline: none;
              border: none;
              min-width: 200px;
              color: white;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
          .duration-control-wrapper {
            padding: 4px 15px;
            margin-top: 15px;
            background: #292934;
            .duration-control {
              list-style: none;
              padding: 0;
              margin-bottom: 0;
              display: flex;
              justify-content: space-evenly;
              .duration-item {
                display: inline-block;
                color: #8d8e9b;
                padding: 0 6px;
                font-size: 12px;
                position: relative;
                cursor: pointer;
                &.active {
                  color: white;
                }
                &:last-child {
                  &::after {
                    content: none;
                  }
                }
                &::after {
                  content: '';
                  width: 2px;
                  height: 80%;
                  margin-left: 6px;
                  position: absolute;
                  background: #474654;
                  top: 50%;
                  transform: translateY(-45%);
                }
              }
            }
          }
          .broker-stats-wrapper {
            .broker-stats {
              display: flex;
              padding: 15px 15px;
              border-bottom: 1px solid #474654;
              &:last-child {
                border-bottom: none;
              }
              &.up {
                .stats-item-title {
                  color: #70ba96;
                }
                .stats-item-value {
                  color: #70ba96;
                }
              }
              .stats-item-title {
                flex-grow: 1;
                text-align: start;
                color: #cb4d49;
                margin-bottom: 0;
                margin-left: 5px;
              }
              .stats-item-value {
                color: #cb4d49;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .main-nav-wrapper {
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    margin-left: -30px;
    margin-right: -30px;
    padding-right: 30px;
    padding-left: 30px;
    @include sm {
      flex-direction: column;
    }
    .main-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      transition: all 400ms linear;
      &.menu-closed {
        margin: 0 auto;
        transform: scale(1);
      }
      .main-nav-item {
        color: #010b1e;
        display: inline-block;
        margin-right: 3rem;
        font-size: 1rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        cursor: pointer;
        position: relative;
        transition: all 500ms ease-in-out;
        &.active {
          &::before {
            content: '';
            position: absolute;
            height: 1px;
            border-radius: 4px;
            width: 100%;
            bottom: 0;
            left: 50%;
            background: #ffffff;
            transform: translateX(-50%);
          }
        }
      }
    }
    .share-wrapper {
      display: flex;
      margin-bottom: 10px;
      .share-btn {
        background: linear-gradient(270deg, #ffffff -35.9%, #186ab4 141.3%);
        padding: 8px 35px;
        font-weight: bold;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }

  .cover-chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    .chart-canvas {
      display: flex;
      width: 100%;
      flex: 1;
      .tv-lightweight-charts {
        margin-top: auto;
      }
    }
    .not-supported-mask {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #010b1e;
      font-size: 18px;
    }
  }
}
