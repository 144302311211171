.all-apps-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  .close-btn {
    position: absolute;
    left: 40px;
    top: 40px;
    .close-icon {
      width: 26px;
      height: 26px;
    }
  }
  .controller-container {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 4rem 6rem;
    .influence-logo {
      width: 400px;
    }
    .category-item {
      display: flex;
      align-items: center;
      margin-top: 4rem;
      opacity: 0.25;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
      .category-image {
        width: 60px;
        height: 60px;
      }
      .category-name {
        margin-left: 15px;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }
  .carousel-container {
    flex: 1;
    // background-color: #08152d;
    display: flex;
    .app-carousel {
      display: flex;
      flex: 1;

      .page-item {
        // flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .app-logo {
          width: 210px;
        }
        .actions-container {
          width: 200px;
          display: flex;
          flex-direction: column;
          margin-top: 5rem;
          .login-button {
            border-width: 1px;
            border-style: solid;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: 600;
          }
          .divider {
            margin: 20px 0;
            height: 1px;
          }
          .register-button {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
}
