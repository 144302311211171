.bank-page-wrapper {
  color: $primary-color;
  .header-container {
    display: flex;
    align-items: center;
    padding: 20px 0;
    height: 200px;
    .header-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      &.big {
        border-right: 1px solid $border-color;
        border-left: 1px solid $border-color;
        .header-value-name {
          font-size: 22px;
        }
        .header-value {
          font-size: 62px;
        }
      }
      .header-value-name {
        font-weight: 700;
        font-size: 15px;
        text-align: center;
      }
      .header-value {
        font-weight: 700;
        font-size: 35px;
        text-align: center;
      }
    }
  }
  .month-tab-container {
    display: flex;
    background-color: $primary-color;
    .month-tab-item {
      flex: 1;
      text-align: center;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 500;
      font-size: 14px;
      @include hover-zoom(1.1);
      &.active {
        font-weight: 700;
        text-decoration: underline;
        font-size: 16px;
        transform: none;
      }
    }
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .list-item {
      display: flex;
      padding: 0 30px;
      margin-top: 30px;
      @include hover-zoom(1.01);
      .item-icon {
        width: 50px;
        height: 50px;
        margin-right: 30px;
      }
      .item-details {
        flex: 1;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
      }
      .item-name {
        font-weight: 500;
        font-size: 18px;
      }
      .item-sub-title {
        color: '#697A8B';
        opacity: 0.5;
        font-size: 14px;
      }
      .item-amount {
        font-weight: 500;
        font-size: 18px;
        text-align: right;
        color: #ce0b0b;
        &.up {
          color: #31aa52;
        }
      }
      .item-usd-amount {
        color: '#697A8B';
        opacity: 0.5;
        font-size: 14px;
        text-align: right;
      }
    }
  }
}
