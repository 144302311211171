.training-container {
  overflow: auto;
  color: white;
  scroll-behavior: smooth;
  .filter-wrapper {
    position: fixed;

    .ant-dropdown-trigger {
      width: 100%;
      margin-bottom: 2rem;
    }

    .filter-title {
      line-height: 27px;
      text-transform: none;
      font-weight: bold;
      font-size: 0.85rem;
      margin-bottom: 1.5rem;
      padding-left: 0.5rem;
    }

    .filter-menu {
      padding-left: 0;
      list-style: none;

      .filter-menu-item {
        color: #2f2f2f;
        font-size: 0.8rem;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
        cursor: pointer;
        white-space: pre-wrap;

        &:hover {
          background-color: rgba(158, 158, 158, 0.1);
        }

        &.active {
          background-color: rgba(158, 158, 158, 0.3);
        }
      }
    }
  }

  .main-video-wrapper {
    height: 100%;

    .main-video-container {
      height: 100%;

      video {
        width: 100%;
        height: auto;
      }
    }
  }

  .main-video-details-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .main-video-details {
      .video-date {
        font-size: 0.7rem;
      }

      .video-title {
        font-size: 1.2rem;
        color: #010b1e;
      }

      .video-desc {
        max-height: 200px;
        overflow: auto;
        font-size: 0.75rem;
        color: #010b1e;
      }
    }
  }

  .videoItem {
    float: left;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    .thumbnail {
      position: relative;
      display: inline-block;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;

      img {
        position: relative;
        display: block;
        width: 100%;
        top: 0;
        left: 0;
      }

      &:hover:after {
        opacity: 0;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.25);
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
      }
    }

    .duration {
      position: absolute;
      height: 18px;
      z-index: 2;
      bottom: 5px;
      right: 5px;
      background: rgba(0, 0, 0, 0.9);
      color: #010b1e;
      padding: 2px 6px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .play {
      position: absolute;
      display: block;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-image: url(https://static1.dmcdn.net/images/home/play.png.v11b2fefa92a0dc9b);
      height: 36px;
      left: 50%;
      margin-left: -18px;
      margin-top: -18px;
      top: 50%;
      width: 36px;
      z-index: 2;
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    .category {
      font-size: 11px;
      margin: 2px 0;
      text-transform: uppercase;
      font-weight: bold;
      color: #aaa;
    }

    .title {
      font-weight: bold;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
      color: #010b1e;
    }

    .author {
      font-size: 13px;
      margin-bottom: 0;
      color: #010b1e;
    }
  }

  @media (min-width: 1600px) {
    .main-video-details-wrapper .main-video-details .video-date {
      font-size: 0.85rem;
    }
  }

  @media (max-width: 576px) {
    .main-video-details-wrapper .main-video-details .video-date {
      margin-top: 5px;
    }
  }

  @media (min-width: 1600px) {
    .main-video-details-wrapper .main-video-details .video-title {
      font-size: 1.35rem;
    }
  }

  @media (min-width: 1600px) {
    .main-video-details-wrapper .main-video-details .video-desc {
      font-size: 1rem;
    }
  }

  @media (max-width: 576px) {
    .main-video-details-wrapper .main-video-details .video-desc {
      max-height: 100px;
    }
  }

  @media (max-width: 576px) {
    .filter-wrapper {
      position: -webkit-sticky;
      position: sticky;
      top: 2rem;
      z-index: 9999999;
      width: -webkit-fill-available;
    }
  }

  @media (min-width: 1600px) {
    .filter-wrapper .filter-title {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 576px) {
    .filter-wrapper .filter-menu {
      margin-bottom: 2rem;
    }
  }

  @media (min-width: 1600px) {
    .filter-wrapper .filter-menu .filter-menu-item {
      font-size: 1rem;
    }
  }

  @media (max-width: 576px) {
    .filter-wrapper .filter-menu .filter-menu-item {
      font-size: 1rem;
    }
  }
}
