.modal-popup-container {
  background-color: white;
  width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  // border: 1px solid white;
  max-width: fit-content;
  border-radius: 50px;
  .header-container {
    // background-color: $primary-color;
    color: white;
    font-weight: 700;
    font-size: 18px;
    // padding: 15px;
    text-align: center;
    font-size: 1.4rem;
  }
  h1{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
display: flex;
align-items: center;
margin-left: 2rem;
color: #18191D;
margin-bottom: 1.2rem;
  }
  .modal-content {
    padding: 35px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .modal-text {
      text-align: center;
      margin-bottom: 30px;
    }
    .action-container {
      display: flex;
    }
    .action-filled {
      flex: 1;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary-color;
      color: white;
      margin-right: 10px;
      border-radius: 8px;
      font-weight: 600;
      cursor: pointer;
    }
    .action-outlined {
      flex: 1;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 8px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
