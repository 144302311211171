.login-page {
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  color: $primary-color;
  display: flex;
  position: relative;
  .actions-btn {
    background: #ffffff;
    border: 1px solid #0f152c;
    color: #0f152c;
    padding: 15px;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    font-weight: 600;
    @include hover-zoom(1.1);
    &.inverted {
      background: #0f152c;
      color: #ffffff;
    }
  }
  .page-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .broker-logo {
      width: 350px;
      margin-bottom: 4rem;
    }
    .login-actions {
      text-align: center;
      transition: all 0.2s linear;
      width: 300px;
    }
  }
  .login-page-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    @include sm {
      overflow: hidden;
    }
    .login-text {
      margin-bottom: 4rem;
      font-size: 0.85rem;
    }
    .broker-logo {
      width: 380px;
      margin-bottom: 4rem;
      margin-top: 25vh;
      @include sm {
        margin-top: 5rem;
      }
    }
    .login-action-container {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .passwd-reset-text {
      margin-top: auto;
      margin-bottom: 3rem;
      font-weight: 500;
      color: $primary-color;
      font-size: 1rem;
      cursor: pointer;
      span {
        font-weight: 700;
      }
      @include hover-zoom(1.05);
    }
    .reset-action {
      margin-top: 15vh;
    }
    .side-image {
      height: 100vh;
      object-fit: cover;
    }
    .login-form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .separator {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      margin: 1rem 0;
    }
    .forgot-input-container {
      position: relative;
      width: 380px;
      .validity-status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #d6323f;
        position: absolute;
        top: 30px;
        right: 0;
        &.true {
          background: #59c36a;
        }
      }
    }
    .form-input {
      background: transparent;
      border-bottom: 1px solid #d9d9d9;
      color: #0f152c;
      padding: 20px 2px;
      margin-bottom: 3rem;
      width: 380px;
      @include xl {
        width: 400px;
      }
      &::placeholder {
        color: #d9d9d9;
      }
    }
    .actions-btn {
      width: 250px;
    }
    .otp-header {
      font-weight: 500;
      margin-bottom: 50px;
    }
    .input-field {
      flex: 1;
      margin-right: 10px;
      background: transparent;
      border: none;
      padding: 15px 4px;
      color: $primary-color;
      &::placeholder {
        color: #878788;
      }
    }
    .otp-container {
      display: flex;
      width: 500px;
      height: 40px;
      div {
        flex: 1;
        div {
          &:nth-child(1) {
            flex: 1;
          }
        }
      }
    }
    .otp-input {
      font-weight: bold;
      font-size: 2rem;
      border: none;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
  }
  .login-loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loading-text {
      text-align: center;
      font-size: 1rem;
      color: $primary-color;
    }
  }
}

.login-brand-selector-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .brand-selector-container {
    display: flex;
    flex-direction: column;
    width: 50vw;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid $border-color;
    .header-container {
      display: flex;
      background-color: $primary-color;
      align-items: center;
      padding: 15px 30px;
      position: relative;
      .header-action {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        @include hover-zoom(1.2);
        .header-action-icon {
          color: white;
          height: 18px;
          width: 18px;
        }
      }
      .logo-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        z-index: 0;
        justify-content: center;
        .app-logo {
          margin: auto;
          height: 30px;
        }
      }
      .arrow-container {
        margin-right: 20px;
        animation: blink 1s linear infinite;
        z-index: 1;
        cursor: pointer;
        .arrow-icon {
          height: 18px;
        }
      }
    }
    .list-header {
      text-align: center;
      width: 100%;
      font-weight: 600;
      margin-top: 30px;
    }
    .brand-list-container {
      padding: 30px;
      padding-top: 0;
      display: flex;
      overflow-y: auto;
      height: 400px;
      align-items: center;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }
      .empty-text {
        text-align: center;
        width: 50%;
        font-weight: 500;
        font-size: 1.1rem;
        margin: auto;
        line-height: 2;
      }
      .brand-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        height: 130px;
        padding: 0 50px;
        position: relative;
        @include hover-zoom(1.1);
        &:hover {
          .edit-button {
            opacity: 1;
          }
        }
        .bran-img-container {
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          padding: 12px;
          border-radius: 50%;
          .brand-image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
        }
        .brand-name {
          text-align: center;
          font-size: 1rem;
          margin-top: 10px;
        }
        .edit-button {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 25px;
          top: -5px;
          opacity: 0;
          width: 25px;
          height: 25px;
          @include hover-zoom(1.2);
          .edit-icon {
            width: 15px;
            height: 15px;
            margin: auto;
          }
        }
      }
    }
  }
  .search-container {
    border-bottom: 1px solid $border-color;
    padding: 0 30px;
    transition: all 200ms linear;
    height: 50px;
    display: flex;
    &.h-0 {
      height: 0;
      overflow: hidden;
    }
    input {
      color: $primary-color;
      font-size: 0.9rem;
      width: 100%;
    }
  }
}
