.brands-page-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  .all-brands-container {
    margin: 0;
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    .brand-item {
      height: calc(50vh - 40px);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      &.brand-skeleton {
        border: 1px solid $border-color;
        border-radius: 0;
      }
      &:hover {
        .brand-name {
          opacity: 1;
        }
      }
      .brand-icon {
        width: 140px;
        height: 140px;
        object-fit: contain;
        margin: auto;
      }
      .brand-name {
        color: white;
        font-weight: 600;
        text-align: center;
        font-size: 1.2rem;
        margin-bottom: 20px;
        opacity: 0;
        transition: all 200ms ease-in-out;
      }
    }
  }
}

.brand-details-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .cover-img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
    z-index: 1;
  }
  .brand-details-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-top: -45px;
    justify-content: space-between;
    transition: all 200ms linear;
    padding: 0 80px;
    .back-button {
      background-color: white;
      position: absolute;
      left: 50px;
      top: 65px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        font-size: 1.2rem;
        margin-right: 10px;
      }
    }
    .brand-icon-container {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 15px;
      .brand-icon {
        width: 90px;
        height: 90px;
      }
    }
    .brand-name {
      text-align: center;
      font-weight: bold;
      margin-top: 36px;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      i {
        font-size: 1.8rem;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .brand-description {
      text-align: center;
      max-width: 444px;
      color: $primary-color;
    }
    .actions-container {
      display: flex;
      width: 444px;
      margin-bottom: 50px;
      .action-item {
        border: 1px solid $border-color;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 50px;
        cursor: pointer;
        &.active {
          border: none;
          .action-text {
            color: white;
          }
        }
        .action-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .action-text {
          font-weight: 600;
        }
      }
    }
  }
}

.b-pr-mui {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
  .MuiDrawer-paper {
    box-shadow: none;
    border-left: 1px solid #cccccc;
  }
}

.brand-prof-d {
  width: 500px;
  padding: 40px 0 40px 40px;
  overflow: hidden;
  height: 100%;
  .comp-head {
    font-weight: 700;
    color: #2676b1;
    margin-bottom: 0;
  }
  .lice {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .tranl {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 20px;
      max-width: 310px;
      min-width: 310px;
      margin-top: 10px;
      p {
        color: #9a9a9a;
        font-size: 12px;
      }
      h6 {
        font-weight: 600;
        margin: 0 0 0 10px;
        color: #2676b1;
      }
      p {
        color: #9a9a9a;
        font-size: 12px;
      }
    }
    .img-tx-tran {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .rev-strem {
      display: flex;
      overflow: scroll;
      margin-left: -10px;
      padding-left: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.brand-prof-ins-cy {
  width: 500px;
  padding: 40px 40px 0;
  overflow: hidden;
  height: 100%;
  .broker-li-head {
    margin-bottom: 2rem;
    & > h3 {
      font-weight: 700;
      color: #08152d;
    }
    & > h6 {
      color: #2676b1;
    }
  }
  .brok-all-lic {
    overflow: scroll;
    height: calc(100% - 106px);
    &::-webkit-scrollbar {
      display: none;
    }
    .brok-lices {
      background: #ffffff;
      border: 1px solid #e5e5e5;
      padding: 20px;
      margin-bottom: 8px;
      .img-head-a {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        & > img {
          width: 1.6rem;
        }
        & > h5 {
          font-weight: 600;
          margin: 0 0 0 10px;
        }
        p {
          color: #08152d;
        }
      }
      .btn-brk-lice {
        background-color: #fff;
        border: 0.5px solid #e5e5e5;
        color: #08152d;
        border-radius: 0;
        min-width: 9rem;
        font-weight: 600;
        font-size: 0.8rem;
        cursor: pointer;
        &:hover {
          background-color: #08152d;
          border: 0.5px solid #e5e5e5;
          color: #fff;
        }
        &:nth-child(1) {
          margin-right: 8px;
        }
      }
    }
  }
  .license-view-container {
    display: flex;
    flex-direction: column;
    .license-header {
      display: flex;
      align-items: center;
      .license-icon-container {
        filter: drop-shadow(0px 0.75px 4px rgba(0, 0, 0, 0.1));
        padding: 10px;
        background: white;
        .license-icon {
          width: 40px;
          height: 40px;
        }
      }
      .license-details {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        flex: 1;
        .license-name {
          font-weight: 700;
          font-size: 1.3rem;
        }
        .license-intro {
          color: #9a9a9a;
        }
      }
    }
    .license-desc {
      color: #9a9a9a;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .licenses-list {
      display: flex;
      flex-direction: column;
    }
    .list-header {
      font-weight: 700;
      font-size: 1.2rem;
      margin-bottom: 20px;
    }
    .subscription-item {
      display: flex;
      border: 1px solid $border-color;
      border-radius: 6px;
      justify-content: space-between;
      padding: 15px 20px;
      .subscription-name {
      }
      .subscription-cost {
        font-weight: 600;
      }
    }
  }
}
