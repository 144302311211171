
.main-side-left-content {
    width: 400px;
    height: 100vh;
  
    padding: 2rem;
    padding-bottom: 0rem;
    overflow: scroll;
    .top-img {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom:3.5rem;
   img{
  
      transition: all 0.6s ease-in;
    cursor: pointer;
      &:hover {
          transform: scale(1.1);
          transition: all 0.6s ease-in;
      
  }
  }
    }
    .content-inside {
      display: flex;
      align-items: center;
      transition: all 0.6s ease-in;
      margin-top: 2.5rem;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        transition: all 0.6s ease-in;
      }
    }
    h3 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #182542;
    }
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #182542;
    }
    .image-box {
      width: 52.66px;
      height: 52.66px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;
    }
  }
  
.carres {
    height: 40px;
    background-color: white;
    ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    z-index: 999;
    border-bottom: 0.5px solid #E5E5E5;
    cursor: pointer;

    h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;


        margin-bottom: 0;
        color: #FFFFFF;

        img {
            width: 18px;
            height: 18px;
        }
    }
}

// .left-icon  {
//     margin-left: 12px;
//     margin-right: 12px;
// }


.vl-top1 {
    border-left: 0.5px solid #E5E5E5;
    height: 40px;
}

.nvest-card {
    position: absolute;
    background: white;
    z-index: 12;
    left: 8px;
    top: 45px;
}

.nvest-img {
    border: 0.5px solid #EBEBEB;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
}

@media only screen and (max-width: 800px) {
    .carres {
        height: 2rem;

        // background-color: #4CAF50;
        display: none !important;
        align-items: center;
        justify-content: space-between;
        padding-right: 5%;
        position: fixed;
        width: 100%;
        z-index: 999;
        padding-left: 5%;

        h5 {

            font-size: 11px;
        }
    }



    .vl-top1 {
        height: 33px;
    }

    .numsdata {
        display: block;
        padding: 0px;
    }

    .numalign,
    .endalign {
        text-align: center;
    }

    .homepaage .right {
        margin-left: 0px;
    }

}


@media only screen and (max-width: 800px) {
    .numdigit {
        font-size: 55px;
    }

    .carres {
        height: 2.2rem;
        display: none !important;
        align-items: center;
        justify-content: space-between;
        padding-right: 0%;
        position: fixed;
        width: 100%;
        z-index: 999;
        padding-left: 0%;
    }
}

@media only screen and (max-width: 1000px) {
    .secondbg {
        display: none;
    }
}


.sidemenu {
    width: 500px;
    height: 100vh;
    top: 0;
    position: fixed;
    background: white;
    // margin-top: 60px;
    // padding-top: 25px;
    z-index: 11;
    text-align: start;
    border: 1px solid #EDEDED;
    // margin-top: 730px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    // margin-left: 35px;
    border: 0.5px solid #E5E5E5;
    // border-radius: 15px;
}

.pagemask {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden !important;
}

.close-img {
    margin-top: -138px;
    margin-left: -30px;
}

.side-text {
    margin-left: 50px;
}

.card-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: 15px;
}

.card-desc {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.card-data {
    display: flex;
    transition: -webkit-transform .5s cubic-bezier(.4, 0, .2, 1);
    transition: transform .5s cubic-bezier(.4, 0, .2, 1);
    transition: transform .5s cubic-bezier(.4, 0, .2, 1),
        -webkit-transform .5s cubic-bezier(.4, 0, .2, 1);
}

.card-data:hover {
    transform: scale(1.1);
    transition: 0.4s all ease;
    // transition: -webkit-transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1),-webkit-transform .5s cubic-bezier(.4,0,.2,1);}
}

.company-logo {
    border: 0.5px solid #E5E5E5;
    border-radius: 5px;
    padding: 13px;
    width: 52px;
    height: 52px;
}

.card-text {
    margin-left: 10px;
}

.comp-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: 10px;

}

.comp-desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -11px;
}

.rightmenu {
    width: 500px;
    height: 100vh;
    position: fixed;
    background: white;
    padding-top: 25px;
    z-index: 11;
    text-align: start;
    border: 1px solid #EDEDED;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    border: 0.5px solid #E5E5E5;
    top: 0;
    left: 0;
}

.close-img-right {
    margin-top: -138px;
    margin-left: 503px;
}

.right-card-title {
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #5F6163;

}

.right-card-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -13px;
}

.left-icon,
.right-logo {
    margin-left: 13.5px;
    margin-right: 14px;
}

.right-logo:hover,
.left-icon:hover {
    transform: scale(1.1);
    transition: 0.4s all ease;
}

.right-icons {
    display: flex;
    margin-left: auto;
}

@media screen and (min-width:1600px) {

    .left-icon,
    .right-logo {
        margin-left: 25.5px;
        margin-right: 26px;
    }

    .sidemenu {
        // margin-left: 70px;
    }

    .rightmenu {
        // right: 60px;
    }
}