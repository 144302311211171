.NavBar_dash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  -webkit-box-shadow: 0 10px 10px -10px #c0c0c0;
  -moz-box-shadow: 0 10px 10px -10px #c0c0c0;
  box-shadow: 0 10px 10px -10px #c0c0c0;
  height: 66px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: #ffff;
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    color: #18191d;
    &:hover {
      transform: scale(1.1);
    }
  }
  .dropdown-container {
    border-left: 2px solid #e5e5e5;
    padding-left: 2rem;
    display: flex;
    color: #18191d;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    div {
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .text-nav {
    display: flex;
    align-items: center;
    gap: 3rem;
    h5 {
      cursor: pointer;
    }
  }
  .Login {
    margin-bottom: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 42px;
    /* or 280% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;

    justify-content: center;
    background: #18191d;
    border: 0.5px solid #18191d;
    border-radius: 40px;
    width: 133px;
    height: 38px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .buttonHanding {
    display: flex;
    align-items: center;
  }

  .start {
    margin-bottom: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 42px;
    /* or 280% */

    display: flex;
    margin-left: 13px;
    align-items: center;
    text-align: center;

    color: #ffffff;

    justify-content: center;
    background: #cf1a38;
    border: 0.5px solid #cf1a38;
    border-radius: 40px;
    width: 156px;
    height: 38px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.navbarCointainer {
  display: none;
}
.showDropDownAir{
  position: absolute;
  z-index: 9;
  height: 100vh;
  width: 100%;
  top: 0;
  background: white;
  display: none;
}

.AppSection {
  height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3rem;

  .card-app {
    padding-top: 6vh;
    padding-bottom: 6vh;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 2%;
    height: 100vh;
    overflow: scroll;
    cursor: pointer;
  }
  .innerCard {
    width: 32.65%;
    height: 350px;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 6vh;
    padding-left: 6vh;
    &:hover {
      transform: translateY(-5px);
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    /* or 150% */

    display: flex;
    align-items: center;
    margin-top: 2rem;

    color: #343c5b;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 45px;
    line-height: 4px;
    line-height: 4px;

    /* or 250% */

    display: flex;
    align-items: center;

    color: #343c5b;
  }
}
@media only screen and (max-width: 600px) {
  .navbarCointainer {
    display: block;
  }
  .NavBar_dash .dropdown-container {
    display: none;
  }
  .NavBar_dash .text-nav {
    display: none;
  }
  .NavBar_dash .buttonHanding {
    display: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
  .NavBar_dash{
    border-bottom: 7px solid #08152D;
  }
  .NavBar_dash .nav-mob{
    display:flex;
    justify-content:space-between;
    width:90vw;
  }
}