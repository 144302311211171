.main-controller-fragment {
  padding: 30px 50px;
  width: 100%;
  overflow: auto;
  .data-cards-list {
    width: 100%;
    .data-card-item {
      border: 0.5px solid #010b1e;
      padding: 30px 40px;
      margin-right: 20px;
      .title {
        color: #010b1e;
        font-weight: bold;
      }
      .value {
        color: #010b1e;
        font-size: 2rem;
      }
    }
  }
}
