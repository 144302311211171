.ant-modal-content {
  background-color: #1b2333;
  .ant-modal-header {
    background-color: #1b2333;
    border-bottom: none;
    .ant-modal-title {
      color: white;
    }
  }
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-body {
    color: white;
  }
  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      color: white;
    }
    .ant-modal-confirm-content {
      color: white;
    }
  }
}

.drop-down-item {
  display: flex;
  .item-image {
    width: 25px;
    height: 25px;
  }
  .item-name {
    flex: 1;
    padding: 0 10px;
    font-weight: 600;
  }
  .item-span {
  }
}

.ant-dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
