.bot-transactions-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    justify-content: space-between;
    .header-text {
      font-weight: 500;
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
    }
    .header-action {
      font-weight: 500;
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .coin-selector-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .coin-item {
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 10px 20px;
    margin-bottom: 15px;
    cursor: pointer;
    .coin-icon {
      width: 30px;
      height: 30px;
    }
    .coin-name {
      font-weight: bold;
      margin-left: 20px;
    }
  }
  .txns-list {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .transaction-item {
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 10px 20px;
    margin-bottom: 15px;
    cursor: pointer;
    .txn-coin {
      width: 30px;
      height: 30px;
    }
    .txn-details {
      margin-left: 15px;
      .txn-value {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .txn-date {
        font-size: 10px;
      }
    }
  }
  .empty-list-text {
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
  }
  .loading-container {
    margin-top: 40px;
  }
  .transaction-details-viewer {
    flex: 1;
    display: flex;
    flex-direction: column;
    .header-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
      .txn-icon {
        width: 30px;
        height: 30px;
      }
      .txn-header {
        margin-left: 15px;
        .txn-id {
          font-weight: bold;
          font-size: 16px;
        }
        .txn-date {
          font-size: 12px;
        }
      }
    }
    .txn-details {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      .listItem {
        display: flex;
        justify-content: space-between;
        .key {
          flex: 0 0 40%;
          width: 0;
          font-size: 12px;
        }
        .value {
          flex: 0 0 55%;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
        }
      }
    }
    .actions-container {
      display: flex;
      margin-left: -25px;
      margin-right: -25px;
      .action-button {
        text-align: center;
        flex: 1;
        border: 1px solid #e7e7e7;
        padding: 10px 15px;
        cursor: pointer;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
}
