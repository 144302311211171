.mobile-landing-page-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $primary-color;
  .app-icon {
    margin-top: auto;
    width: 45vw;
    margin-bottom: 30px;
  }
  .broker-logo {
    margin-bottom: 40px;
    width: 70vw;
  }
  .desc {
    text-align: center;
    margin-bottom: 50px;
    font-size: 1rem;
    width: 300px;
  }
  .button-outline {
    font-weight: 600;
    border: 1px solid $primary-color;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;
    margin-bottom: 20px;
    color: $primary-color;
  }
  .filled-button {
    font-weight: 600;
    background-color: $primary-color;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;
    color: white;
  }
  .install-container {
    display: flex;
    flex-direction: column;
  }
  .install-action {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color;
    padding: 0 30px;
    font-weight: 600;
    color: $primary-color;
    font-size: 1.2rem;
    width: 230px;
    margin-bottom: 10px;
    @include sm {
      height: 70px;
      padding: 0 25px;
    }
    @include hover-zoom(1.1);
    .install-action-icon {
      height: 40px;
      @include sm {
        height: 30px;
      }
    }
  }
  .footer-action {
    margin-top: auto;
    background-color: $primary-color;
    color: white;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    padding: 20px;
    font-size: 16px;
  }
}
