.innerTextSearch {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #18191d;

  opacity: 0.25;
}
.SearchTop {
  display: flex;
  align-items: center;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.togglecomp {
  margin-left: 1rem;
  margin-right: 1rem;
}
.borderSearch {
  background-color: #ebebeb;
  width: 2px;
  height: 32px;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.BySearch {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.searchBox {
  border: 1px solid #e5e5e5;
  border-radius: 13px;
  margin-top: 7vh;
  width: 655px;
}
.inputBoxText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #18191d;

  opacity: 0.25;
  width: calc(100% - 175px);
  height: 100%;
}
.SeachBottom {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 0.5px solid #e5e5e5;
  padding-left: 2rem;
  padding-right: 2rem;
}
.SearchData {
  width: 177px;
  height: 60px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  justify-content: center;
  background: #18191d;
  border-radius: 40px;
}
.labelTextSearch {
  width: 138px;
  height: 41px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  color: #18191d;

  opacity: 0.5;
  background: #eeeeee;
  border-radius: 40px;
}

.toggle-icon-part {
  align-items: center;
  display: flex;
  justify-content: end;
}

.tgl1[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.tgl2 {
  border: 3px solid #ebebeb;
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 18px;
  background: #ebebeb;
  margin-bottom: 0;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tgl2:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background: #000;
  border-radius: 90px;
  transition: 0.3s;
}

.tgl1:checked + .tgl2 {
  background: rgb(8, 170, 67);
}

.tgl1:checked + .tgl2:after {
  left: calc(100%);
  transform: translateX(-100%);
}

.tgl2:active:after {
  width: 20px;
}

.watchlabel {
  width: 358px;
  height: 75px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 45px;
  /* or 205% */
  position: absolute;
  bottom: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #cf1a38;
  border-radius: 40px;
  img {
    margin-right: 10px;
  }
}
.networkTetlanding {
  display: none;
}
.HeaderMobileDashboard {
  display: none;
}



@media only screen and (max-width: 600px) {
  .tridthSection {
    height: 15rem;
  }
  .landing-footer-wrapper {
    display: none;
  }
  .mobFooter {
    display: flex;
    justify-content: center;
  }
  .searchBox {
    display: none;
  }
  .landing-wrapper .landing-container {
    flex: 1 1;
    display: flex;
    padding-top: 63px;
    padding-bottom: 0px;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
  }

  .landing-wrapper .joinGrow {
    font-size: 1.15rem;
    gap: 0;
    justify-content: space-between;
    img {
        width: 60%;
    }
  }
  .landing-wrapper .header-text {
    display: none;
  }
  .landing-wrapper .header-text1 {
    display: flex;
  }
  .networkTetlanding {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 35px;
    /* or 174% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #cf1a38;
  }
  .SecongLayout {
    display: none;
  }
  .landing-wrapper .feature-img-container {
    width: 100%;

    background-position: 50% 100%;
    background-repeat: no-repeat;
    border-bottom: 0.5px solid #ddd;
    background-size: contain;
    padding-right: 5%;
    height: 100%;
  }
  .landing-wrapper .details-container {
    border: none;
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 0px !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  .watchlabel {
    width: 100%;
    height: 56px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 45px;
    position: initial;
    bottom: 111px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #cf1a38;
    border-radius: 30px;
    margin-top: 2rem;
  }
  .HeaderMobileDashboard {
    // background-color: #000;
    background: #000;
    background: linear-gradient(to bottom right, #000 84%, #ffffff 50%);
    position: relative;
    // padding-top: 2.3rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33rem;
    padding-top: 3.9rem;
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 55px;

      text-align: center;

      color: #ffffff;
    }
  }
  .SeacahMobileSectionDashboard {
    width: 100%;
    padding-bottom: 2.5rem;
    background-color: white;
    border-radius: 49px 49px 0px 0px;
    padding-top: 1rem;
    position: absolute;
    top: 9rem;
    border: 0.5px solid #ebebeb;
    top: 13rem;
    width: 94%;

    .togledivSection {
      display: flex;
      align-items: center;
      margin-top: 1.3rem;
    }
    .searhFromMobile {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      margin-left: 7vw;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      justify-content: center;
      width: 230px;
      height: 64px;

      background: #cf1a38;
      border: 0.5px solid #cf1a38;
      border-radius: 35px;
    }
    input {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;

      color: #18191d;
      width: 362px;
      height: 84px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      background: rgb(235 235 235);
      border-radius: 10px;
      opacity: 0.25;
      width: 100%;
      padding-left: 1rem;
    }
  }
  .searchIndex {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    color: #18191d;
    margin-bottom: 8px;
    opacity: 0.25;
    margin-top: 2.5rem;
  }
  .mobileSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #ddd;
    width: 100%;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .dropDownMobile {
      display: flex;
      align-items: self-start;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;

      color: #18191d;
      img {
        height: 21px;
        margin-right: 10px;
      }
    }
  }
}


@media only screen and (min-width:0px) and (max-width:900px) {
  .landing-wrapper .mobile-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
  }

  .landing-wrapper .mobile-menu .menu-container{
    border-bottom: none;
  }

  .landing-wrapper .feature-img-container{
    height: 60vh;
  }

  .mob-button{
    height: 66px;
    width: 80vw;
    display: flex;
    justify-content: center;
    text-align: center;
    background: #CF1A38;
    border-radius: 35px;
    font-weight: 700;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    cursor: pointer;
    margin-bottom: 60px;
    &:hover{
      transform: scale(1.1);
      transition: all ease .3s;
    }
  }

  .SeacahMobileSectionDashboard{
    margin-top: 30px;
    position: initial;
    width: 95vw;
    padding-bottom: 100px;
  }

  .all-box{
    padding-top: 66px;
  }

  .each-box{
    padding: 70px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    text-align: center;
  }

  .each-box-title{
    font-weight: 300;
    font-size: 11.5px;
    line-height: 27px;
    color: #18191D;
    margin: 20px 0px;
    text-align: center;
  }

  .store-box{
    width: 90vw;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    padding: 18px 10vw;
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }
}