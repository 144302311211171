.site-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  .site-header {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    color: $primary-color;
    position: relative;
    height: 90px;
    .menu-icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 20px 0;
      margin-left: 50px;
      z-index: 1;
      @include hover-zoom(1.1);
      @include sm {
        margin-left: 30px;
      }
    }
    .app-logo-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      .broker-logo {
        cursor: pointer;
        height: 35px;
        @include hover-zoom(1.1);
        @include sm {
          height: 30px;
        }
      }
    }
    .header-action-button {
      cursor: pointer;
      background-color: white;
      width: 100px;
      border-left: 1px solid $border-color;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      position: relative;
      &:hover {
        .tool-tip-container {
          display: block;
        }
      }
      &.right-border {
        border-right: 1px solid $border-color;
        .tool-tip-container {
          left: 20px;
          right: unset;
        }
      }
      @include sm {
        display: none;
      }
      .influence-icon {
        height: 35px;
        @include hover-zoom(1.1);
      }
      .tool-tip-container {
        position: absolute;
        right: 20px;
        bottom: -70px;
        border: 1px solid $border-color;
        padding: 10px 20px;
        transition: all 200ms ease-in-out;
        display: none;
        background-color: white;
        img {
          height: 30px;
        }
      }
    }
    .tooltip {
      font-weight: 600;
      font-family: "Montserrat";
      border: 1px solid #343c5b !important;
      opacity: 1;
      border-radius: 0;
      font-size: 1rem;
    }
  }
}

.web-apps {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  overflow-y: scroll;
  padding-top: 66px;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  .each-app {
    width: calc(100% / 3);
    height: 20rem;
    background: beige;
    padding: 3rem 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    transition: all ease 0.3s;

    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        border-radius: 25px;
    }

    .appHeading {
      font-size: 1.45rem;
      font-weight: 600;
      padding-top: 1rem;
    }
  }
}

.landing-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 5px;
  background-color: #ffffff;
  overflow-y: scroll;

  .mobile-menu {
    width: 100%;
    height: calc(100vh - 66px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 66px;
    right: -100%;
    background: #ffffff;
    z-index: 7;
    transition: all ease 0.3s;
    padding: 1rem 0.5rem;
    .menu-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      min-height: 60%;
      border-bottom: 0.5px solid #ebebeb;
      .menuItm {
        width: 100%;
        height: 3.75rem;
        display: flex;
        align-items: center;
        padding-left: 0.75rem;
        border: none;
        font-size: 0.85rem;
        color: #18191d;
        font-weight: 500;
        border-radius: 10px;
      }
    }
    .app-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      .appIcon {
        padding: 2.5rem 0.5rem;
        img {
          width: 40%;
        }
      }
      .appLinks {
        width: 100%;
        display: flex;
        padding: 0 2.5rem 2.5rem 0.5rem;
        border-bottom: 0.5px solid #ebebeb;
        div {
          width: 50%;
          display: flex;
          img {
            width: 70%;
          }
        }
      }
    }
  }

  .joinGrow {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 900;
    font-size: 2rem;
    line-height: 34px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #cf1a38;
  }
  .landing-container {
    flex: 1;
    display: flex;

    padding-top: 63px;

    padding-bottom: 60px;
  }

  .feature-img-container {
    width: 40%;
    background-image: url(../images/virtaulimage.png);

    background-position: 50% 100%;
    background-repeat: no-repeat;
    border-bottom: 0.5px solid #ddd;
    background-size: contain;
    padding-right: 5%;
  }

  .details-container {
    flex: 1;
    background-color: white;
    // padding: 60px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 0.5px solid #ddd;
    padding-left: 5%;
    padding-bottom: 111px;
    @include sm {
      width: 100vw;
      padding: 30px 25px;
      align-items: center;
    }
  }
  .header-text {
    color: #08152d;
    font-weight: 700;
    line-height: 55px;
    margin-bottom: 1.1rem;
    font-style: italic;
    font-size: 55px;
    // @include sm {
    //   text-align: center;
    //   font-size: 1.4rem;
    //   padding: 0 20px;
    // }
  }

  .header-text1 {
    color: #08152d;
    font-weight: 700;
    line-height: 55px;
    margin-bottom: 1.1rem;
    font-size: 3.4rem;
    display: none;
  }

  .selected-optionss {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    width: 80px;
    border-left: 0.5px solid #ddd;
    // border-right: 1px solid $border-color;
    &.right {
      // border-left: 1px solid $border-color;
      border-right: none;
    }
    &.no-active {
      .option-img {
        opacity: 0.25;
      }
    }
    &.active {
      .option-img {
        opacity: 1;
      }
    }
    .option-img {
      width: 25px;
      height: 25px;
      @include hover-zoom(1.15);
    }
    .option-name {
      font-weight: 700;
      font-size: 1.05rem;
      margin-left: 10px;
    }
    .tooltip {
      font-weight: 600;
      font-family: "Montserrat";
      border: 1px solid $border-color !important;
      opacity: 1;
      border-radius: 0;
      font-size: 1rem;
      opacity: 0.5;
    }
  }
  .search-bar-container {
    display: flex;
    width: 700px;
    border-radius: 40px;
    border: 1px solid $border-color;
    color: $primary-color;
    margin-top: 20px;
    margin-top: 20px;
    align-items: center;
    padding-right: 22px;
    height: 78px;
    margin-top: 4rem;

    .selected-option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 65px;
      width: 80px;
      // border-right: 1px solid $border-color;
      &.right {
        // border-left: 1px solid $border-color;
        border-right: none;
      }
      &.no-active {
        .option-img {
          opacity: 0.25;
        }
      }
      &.active {
        .option-img {
          opacity: 1;
        }
      }
      .option-img {
        width: 25px;
        height: 25px;
        @include hover-zoom(1.15);
      }
      .option-name {
        font-weight: 700;
        font-size: 1.05rem;
        margin-left: 10px;
      }
      .tooltip {
        font-weight: 600;
        font-family: "Montserrat";
        border: 1px solid $border-color !important;
        opacity: 1;
        border-radius: 0;
        font-size: 1rem;
        opacity: 0.5;
      }
    }
    .SeacrhSear {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      width: 177px;
      transition: all 300ms ease-in-out;

      height: 53px;

      justify-content: center;
      background: #18191d;
      border-radius: 40px;
      &:hover {
        transform: scale(1.1);
        transition: all 300ms ease-in-out;
      }
    }
    .search-input {
      flex: 1;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #18191d;

      opacity: 0.25;
      padding: 0 0px;
    }
    .search-icon {
      margin-right: 20px;
      width: 20px;
      height: 20px;
      margin-top: auto;
      margin-bottom: auto;
      transform: rotate(90deg);
    }
  }

  .landing-desc {
    margin-bottom: 30px;
    color: $primary-color;
    line-height: 2;
    padding: 1.875rem 0;
    max-width: 600px;
    font-size: 1.1rem;
    @include sm {
      font-size: 0.9rem;
      width: auto;
      text-align: center;
    }
  }
  .sub-header-text {
    color: #08152d;
    font-weight: normal;
    font-size: 1.6rem;
    @include sm {
      margin-top: 30px;
      text-align: center;
      font-size: 1rem;
    }
  }
  .feature-container {
    display: flex;
    margin-top: 5rem;
    @include sm {
      width: 100vw;
      overflow: auto;
    }
    .feature-item {
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 300ms ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .feature-image {
      width: 400px;
      height: 260px;
    }
    .feature-text {
      margin-top: 20px;
      color: #08152d;
      font-weight: normal;
      font-size: 1rem;
    }
  }
  .mobile-carousel {
    margin-top: 5rem;
    .mobile-item {
      width: 80vw;
      padding: 0 15px;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      .item-image {
        width: 100%;
      }
      .item-button {
        margin-top: 3rem;
        border: 1px solid $primary-color;
        text-align: center;
        font-weight: 500;
        color: #08152d;
        padding: 12px 30px;
        transition: all 300ms ease-in-out;
        font-size: 0.8rem;
      }
    }
  }
}

.site-navbar-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(8, 21, 45, 0.7);
  display: flex;
  transition: all 500ms ease-in;
  z-index: 1;
  .close-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    .close-icon {
      width: 26px;
      height: 26px;
    }
  }
  .overlay {
    flex: 1;
    @include sm {
      flex: 0;
    }
  }
  .nav-container {
    background-color: white;
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 60px 40px;
    @include sm {
      align-items: center;
      width: 0;
      flex-grow: 1;
    }
    .header-image {
      width: 220px;
      @include sm {
        width: 200px;
      }
    }
    .links-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 60px;
      @include sm {
        margin: auto 0;
        justify-content: center;
      }
      .link-item {
        color: #08152d;
        font-weight: 600;
        font-size: 1.4rem;
        cursor: pointer;
        @include sm {
          text-align: center;
          font-size: 1.2rem;
          margin: 35px 0;
        }
      }
    }
    .all-apps-btn {
      border: 1px solid #08152d;
      padding: 15px;
      text-align: center;
      color: #08152d;
      font-weight: 600;
      @include sm {
        width: 200px;
        padding: 10px;
      }
    }
  }
}
.SecongLayout {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-top: 63px;
  padding-bottom: 80px;
  padding-right: 5%;
  h1 {
    width: 739px;
    height: 139px;
    left: 630px;
    top: 204px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 55px;
    /* or 138% */
    margin-bottom: 0;

    color: #18191d;
    span {
      color: #cf1a38;
    }
  }

  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    color: #08152d;
    margin: 0;
    margin-bottom: 11px;
  }

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    margin-bottom: 0;
    color: #08152d;
  }
  .cardsSecondLayout {
    border-left: 5px solid #cf1a38;
    width: 50%;
    width: 50%;
    padding-left: 1.4rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 6vh;
  }
  .cardlayoutTop {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 739px;
    margin-top: 18vh;
  }
  .golbalimg {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      max-width: fit-content;
      display: flex;
      align-items: center;
      width: 100%;
      -webkit-animation: spin 30s linear infinite;
      -moz-animation: spin 30s linear infinite;
      animation: spin 30s linear infinite;
    }
    //   @-moz-keyframes spin {
    //     100% { -moz-transform: rotate(360deg); }
    // }
    // @-webkit-keyframes spin {
    //     100% { -webkit-transform: rotate(360deg); }
    // }
    // @keyframes spin {
    //     100% {
    //         -webkit-transform: rotate(360deg);
    //         transform:rotate(360deg);
    //     }
    //   }
  }
  .rightSide {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 8vw;
    justify-content: center;
  }
}

.landing-footer-wrapper {
  display: flex;
  justify-content: center;
  background: #18191d;
  overflow: hidden;
  height: 60px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-left: 5%;
  padding-right: 5%;

  .enter {
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      transform: scale(1.07);
    }
  }

  .bottNameArrow {
    display: flex;
    align-items: center;
    .rightImage {
      position: relative;
      -webkit-animation: linear infinite;
      -webkit-animation-name: run;
      -webkit-animation-duration: 2s;
    }
    @-webkit-keyframes run {
      0% {
        left: 0;
      }
      50% {
        left: 100%;
      }
      100% {
        left: 0;
      }
    }
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #ffffff;
    margin-right: 15px;
  }
  .footer-action {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $border-color;
    padding: 0 50px;
    font-weight: 600;
    color: $primary-color;
    font-size: 1.2rem;
    width: 230px;
    &.right {
      border-right: 1px solid $border-color;
    }
    @include sm {
      height: 70px;
      padding: 0 25px;
      flex: 1;
    }
    @include hover-zoom(1.1);
    .footer-store-icon {
      height: 31px;
      @include sm {
        height: 25px;
      }
    }
  }
}

.mobFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #18191d;
  display: none;
  height: 4rem;
  z-index: 5;
  img {
    width: 80%;
  }
}

.Typist {
  font-weight: 700;
  margin-top: 10px;
  font-size: 2.5rem;
  .Cursor {
    display: inline-block;
  }
  .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.search-options-wrapper {
  padding-top: 0;
  // display: flex;
  // height: 250px;
  // align-items: center;
  .handle-modle-text {
    margin-left: 1.5rem;
  }
  .option-item {
    width: 582px;

    display: flex;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    border-radius: 25px;
    position: relative;
    color: $primary-color;
    height: 97px;
    cursor: pointer;
    margin-top: 1rem;
    // background: rgba(235, 235, 235, 0.51);
    &:hover {
      background: rgba(235, 235, 235, 0.51);
    }
    // @include hover-zoom(1.1);
    &.active {
      .option-name {
        // font-weight: 700;
      }
    }
    &:hover {
      .edit-button {
        opacity: 1;
      }
      .option-name {
        // font-weight: 600;
        font-weight: 700;
      }
      .option-app-no {
        display: block;
      }
    }
    .bran-img-container {
      // background: #ffffff;
      // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      // padding: 25px;
      // border-radius: 50%;
      .option-image {
        width: 49px;
        height: 49px;
      }
    }
    .option-name {
      font-weight: 700;
      font-size: 1rem;
    }
    .option-name-title {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      margin-top: 3px;
      color: #18191d;
    }
    .option-app-no {
      display: none;
      text-align: center;
      font-size: 0.8rem;
    }
    .edit-button {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 40px;
      top: -5px;
      opacity: 0;
      width: 25px;
      height: 25px;
      @include hover-zoom(1.2);
      .edit-icon {
        width: 15px;
        height: 15px;
        margin: auto;
      }
    }
  }
}

.landing-search-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .search-layout-header {
    display: flex;
    border-bottom: 1px solid $border-color;
    height: 77px;

    .app-logo-container {
      border-right: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 50px;
      .app-img {
        height: 25px;
        @include hover-zoom(1.1);
      }
    }
    .search-input {
      flex: 1;
      padding: 0 30px;
    }
    .search-input-btn {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 30px;
      cursor: pointer;
      color: $primary-color;
    }
    .selected-option {
      display: flex;
      align-items: center;
      height: 100%;
      width: 90px;
      justify-content: center;
      border-left: 1px solid $border-color;
      @include hover-zoom(1.05);
      .option-img {
        // width: 25px;
        // height: 25px;
      }
      .option-name {
        // font-weight: 700;
        // font-size: 1.05rem;
        // margin-left: 10px;
      }
    }
  }
  .search-list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    color: $primary-color;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-top: 0px;
    .empty-message {
      text-align: center;
      font-weight: 500;
      font-size: 22px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(5, 5, 5, 0.5);
      position: relative;
      .tooltip-container {
        position: absolute;
        border: 1px solid $border-color;
        top: 20px;
        right: 90px;
        width: 280px;
        background-color: white;
        z-index: 99;
        color: rgba(5, 5, 5, 0.5);
        font-weight: 500;
        font-size: 18px;
        padding: 20px;
        animation: blinker 1.5s linear infinite;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -15px;
          margin-top: -15px;
          width: 0;
          height: 0;
          border-bottom: solid 15px $border-color;
          border-left: solid 15px transparent;
          border-right: solid 15px transparent;
        }
      }
    }
    .search-item-container {
      overflow: hidden;
    }
    .search-item {
      display: flex;
      border-bottom: 1px solid $border-color;
      padding: 0 5%;
      padding-right: 80px;
      height: 145px;
      align-items: center;
      overflow: hidden;
      @include hover-zoom(1.02);
      position: relative;
      position: 0;
      .item-details {
        display: flex;
        align-items: center;
        flex: 1;
        .item-img {
          width: 55px;
          height: 55px;
          border-radius: 50%;
        }
        .name-container {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          .item-name {
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: capitalize;
          }
          .item-email {
            font-weight: 300;
          }
        }
      }
      .item-feature-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        &.center {
          justify-content: center;
        }
      }
      .item-feature {
        display: flex;
        align-items: baseline;
        z-index: 2;
        @include hover-zoom(1.1);
        .item-feature-value {
          font-weight: 700;
          font-size: 1.7rem;
        }
        .item-feature-label {
          margin-left: 5px;
        }
      }
    }
  }
}

.pagination-container {
  color: $primary-color;
  display: flex;
  height: 60px;
  border-top: 1px solid $border-color;
  .title {
    font-weight: 600;
    border-right: 1px solid $border-color;
    display: flex;
    align-items: center;
    padding: 0 50px;
  }
  .page-count-item {
    border-right: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    @include hover-zoom(1.1);
    &.active {
      color: white;
      background-color: $primary-color;
      font-weight: 700;
      &:hover {
        transform: none;
      }
    }
  }
  .page-control-button {
    border-left: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    @include hover-zoom(1.1);
    .page-control-icon {
      width: 25px;
      height: 25px;
      &.right {
        transform: rotate(180deg);
      }
    }
  }
  .current-page {
    border-left: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    font-weight: 600;
  }
}

.user-selected-view-wrapper {
  flex: 1;
  display: flex;
  color: #18191d;
  .user-carousel-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .carousel-container {
      width: 100%;
      // height: 100%;
      .slick-list {
        // height: 100% !important;
        .slick-track {
          will-change: transform;
        }
        .slick-slide {
          & > div {
          }
        }
      }
    }
    .user-carousel-item-wrapper {
      .user-carousel-item-container {
      }
    }
    .user-carousel-item {
      display: flex;
      align-items: center;
      height: 150px;
      cursor: pointer;
      padding-left: 50px;
      &:hover {
        .user-img {
          opacity: 1;
        }
        .user-details {
          .user-name {
            opacity: 1;
          }
          .user-email {
            opacity: 0.5;
          }
        }
      }

      &.active {
        .user-img {
          width: 110px !important;
          height: 110px !important;
          opacity: 1;
        }
        .user-details {
          .user-name {
            opacity: 1;
            font-size: 45px;
          }
          .user-email {
            opacity: 0.5;
            font-size: 18px;
          }
        }
      }
      .user-img {
        width: 70px !important;
        height: 70px !important;
        opacity: 0.5;
        transition: all 500ms ease-in-out;
      }
      .user-details {
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        .user-name {
          font-weight: 700;
          font-size: 28px;
          opacity: 0;
          transition: all 200ms ease-in-out;
          text-transform: capitalize;
        }
        .user-email {
          opacity: 0;
          transition: all 200ms ease-in-out;
          font-size: 13px;
        }
      }
    }
  }
  .user-stats-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid $border-color;
    overflow: hidden;
    .stats-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $border-color;
      width: 280px;
      @include hover-zoom(1.1);
      &.active {
        opacity: 1;
        transform: none;
      }
      &.not-active {
        opacity: 0.6;
      }
      .stats-value {
        text-align: center;
        font-weight: 700;
        font-size: 2.5rem;
      }
      .stats-label {
        text-align: center;
        color: rgba(8, 21, 45, 0.5);
        font-size: 0.8rem;
      }
    }
  }
  .user-stats-overview {
  }
}
