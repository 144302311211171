.marketplace-container {
  // height: 45vh;
  top: 3rem;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #010b1e;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #010b1e;
    border-radius: 8px;
    background: none;
    border-radius: 8px;
  }

  .marketplace-details-header {
    i {
      font-size: 4rem;
    }

    .details-img-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .details-img-wrapper {
        background-color: #fff;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 50px;
        height: 50px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .details-header-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 100%;

      .title {
        color: #010b1e;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
      }

      .sub-title {
        color: #010b1e;
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      .intro {
        font-size: 0.85rem;
        color: #010b1e;
        margin-bottom: 0;
      }
    }
  }

  .marketplace-details-main {
    margin-top: 2rem;

    .meta-wrapper .meta-item {
      margin-bottom: 0.5rem;

      .meta-title {
        width: 100%;
        color: #010b1e;
        font-weight: bold;
        margin-bottom: 0;
      }

      .meta-value {
        width: 100%;
        color: #010b1e;
      }
    }

    .details-main .details-content {
      margin-bottom: 1rem;
      color: #010b1e;
      &:last-child {
        margin-bottom: 0;
      }

      h6 {
        color: #010b1e;
        font-weight: bold;
      }
    }
  }
}

.product-card {
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 16px 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  }

  .title-wrapper {
    margin-top: 1rem;
    margin-bottom: 0.35rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;

    .img-wrapper img {
      height: 1.4rem;
    }

    .main-title {
      margin-left: 10px;
      font-size: 1.2rem;
      color: #010b1e;
    }
  }

  .intro-wrapper {
    .sub-title {
      color: #010b1e;
      font-size: 0.8rem;

      span {
        font-weight: bold;
      }
    }

    .intro {
      color: #010b1e;
      font-size: 0.7rem;
    }

    .type {
      color: #010b1e;
      font-size: 0.8rem;

      span {
        font-weight: bold;
      }
    }
  }
}

.broker-modal-container {
  box-shadow: 2px 4px 9px 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.04);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 2rem;
  text-align: center;
  max-width: 500px;
  border-radius: 4px;
  .login-icon {
    width: 180px;
    margin-bottom: 2rem;
  }
  .login-header {
    font-weight: bold;
    color: #515978;
  }
  .desc {
    color: #515978;
    font-size: 16px;
  }
  .pin-input-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .error-wrapper {
    margin-top: 2rem;
  }
  .login-btn-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    .ant-btn {
      padding: 10px 32px;
      font-weight: bold;
      font-size: 1.1rem;
      height: auto;
      background-color: #196ab4;
      border-color: #196ab4;
    }
    .reset-link {
      margin-top: 10px;
      color: #196ab4;
    }
  }
}

@media (min-width: 1600px) {
  .product-card .title-wrapper .main-title {
    font-size: 1.35rem;
  }
}

@media (min-width: 1600px) {
  .product-card .intro-wrapper .sub-title {
    font-size: 0.9rem;
  }
}

@media (min-width: 1600px) {
  .product-card .intro-wrapper .intro {
    font-size: 0.85rem;
  }
}

@media (min-width: 1600px) {
  .product-card .intro-wrapper .type {
    font-size: 0.9rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container {
    height: 58vh;
  }
}

@media (min-width: 1600px) {
  .marketplace-container
    .marketplace-details-header
    .details-header-wrapper
    .title {
    font-size: 1.75rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container
    .marketplace-details-header
    .details-header-wrapper
    .sub-title {
    font-size: 1.25rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container
    .marketplace-details-header
    .details-header-wrapper
    .intro {
    font-size: 1rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container .marketplace-details-main .meta-wrapper .meta-item {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container
    .marketplace-details-main
    .meta-wrapper
    .meta-item
    .meta-title {
    font-size: 1rem;
  }
}

@media (min-width: 1600px) {
  .marketplace-container
    .marketplace-details-main
    .meta-wrapper
    .meta-item
    .meta-value {
    font-size: 1rem;
  }
}
