.currency-convert-wrapper {
  position: relative;

  .converter-card {
    padding: 1rem 1.5rem;
    border-radius: 6px;

    &.converted {
      background-color: rgba(25, 106, 180, 0.1);

      .ant-dropdown-trigger {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }

    .controls {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(217, 217, 217, 0.5);

      .ant-btn-link {
        color: #196ab4;
      }

      .ant-dropdown-trigger {
        color: #196ab4;
        border: none;
        background-color: rgba(25, 106, 180, 0.1);
      }

      img {
        padding-right: 10px;
      }

      .tittle {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 0.85rem;
        color: #010b1e;
      }
    }

    .input-wrapper {
      margin-top: 0.5rem;

      .input-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        .ant-input-number {
          border: none;
          height: auto;
          padding: 0.25rem 0;
          min-width: 180px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          background-color: transparent;

          .ant-input-number-input {
            font-size: 2rem;
            height: auto;
            text-align: center;
            color: #010b1e;
          }
        }

        .input-currency {
          color: #010b1e;
          font-size: 1.2rem;
          font-weight: bold;
          margin-right: 15px;
          -ms-flex-item-align: center;
          -ms-grid-row-align: center;
          align-self: center;
        }
      }

      .input-valuation {
        margin-top: 0.5rem;
        text-align: center;
        color: #010b1e;
      }
    }
  }

  .swap-icon-wrapper {
    position: absolute;
    width: 60px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    bottom: 50%;
    z-index: 2;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

    &.left-side {
      left: 33.333%;
      -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);
      transform: translate(-50%, 50%);
    }

    &.right-side {
      right: 33.333%;
      -webkit-transform: translate(50%, 50%);
      -ms-transform: translate(50%, 50%);
      transform: translate(50%, 50%);
    }

    .swap-icon-container {
      width: 45px;
      height: 45px;
      margin: auto;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: #196ab4;

      .anticon {
        font-size: 1.5rem;
        color: white;

        svg {
          margin: 0;
        }
      }
    }
  }
}

.commission-form {
  margin-top: 3rem;

  tbody {
    margin: auto;
  }

  .input-wrapper {
    .current-fee {
      font-size: 1.2rem;

      span {
        font-weight: bold;
      }
    }

    .commission-input {
      margin-left: 20px;
      margin-right: 20px;
      border-radius: 4px;
      border: 1px solid #eee;
      padding-left: 5px;
      padding-right: 5px;
      height: 32px;
    }
  }
}

.stake-actions {
  margin-right: 10px;
}

@media (max-width: 576px) {
  .currency-convert-wrapper .converter-card {
    margin-bottom: 60px;
  }
}

@media (min-width: 1600px) {
  .currency-convert-wrapper .converter-card .controls .tittle {
    font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .currency-convert-wrapper .swap-icon-wrapper.left-side {
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -80%);
    -ms-transform: translate(-50%, -80%);
    transform: translate(-50%, -80%);
  }
}

@media (max-width: 576px) {
  .currency-convert-wrapper .swap-icon-wrapper.right-side {
    left: 50%;
    bottom: 30%;
    -webkit-transform: translate(-50%, -30%);
    -ms-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
  }
}

@media (max-width: 576px) {
  .currency-convert-wrapper .swap-icon-wrapper .swap-icon-container .anticon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
