.earn-top-nav {
  padding-left: 50px !important;
  padding-right: 50px !important;
  .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 200ms ease-in-out;
    &.active {
      font-weight: bold;
      border-bottom: 1px solid #2a2e39;
    }
    .menu-title {
      color: #08152d;
    }
  }
}

.earn-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  .brands-categories-list {
    display: flex;
    .brand-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      opacity: 0.5;
      border: 1px solid #e7e7e7;
      margin-right: 15px;
      padding-top: 20px;
      padding-bottom: 10px;
      cursor: pointer;
      &.active {
        opacity: 1;
        border: 1px solid #010b1e;
        .brand-name {
          font-weight: 600;
        }
      }
      .brand-icon {
        width: 50px;
        height: 50px;
      }
      .brand-name {
        color: #464b4e;
        font-size: 11px;
        margin-top: 10px;
      }
    }
  }
  .brand-details {
    flex: 1;
    margin-top: 30px;
    margin-left: -50px;
    margin-right: -50px;
    margin-bottom: -50px;
    border-top: 1px solid #e7e7e7;
    display: flex;
    .brand-list-container {
      padding: 0 50px;
      padding-top: 15px;
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      .empty-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .list-header {
        .header {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .bread-crumbs {
          display: flex;
          align-items: center;
          i {
            margin: 0 5px;
          }
          .item {
            font-size: 0.7rem;
            cursor: pointer;
            transition: all 200ms ease-in-out;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
      .list {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        margin-top: 20px;
        &::-webkit-scrollbar {
          width: 0;
        }
        .list-item {
          display: flex;
          border: 1px solid #e7e7e7;
          margin-bottom: 20px;

          cursor: pointer;
          .item-icon {
            height: 90px;
            width: 90px;
          }
          .item-details {
            flex: 1;
            border-left: 1px solid #e7e7e7;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px 20px;
            .item-name-container {
              display: flex;
              justify-content: space-between;
              .item-name {
                font-weight: 500;
                font-size: 1rem;
              }
              .numbers {
                font-size: 0.75rem;
                i {
                  margin-right: 5px;
                }
              }
            }
          }
          .no-of-licenses {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

.brand-details-sidebar {
  width: 30%;
  border-left: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    align-items: center;
    padding: 40px 30px;
    .header-logo {
      width: 40px;
      width: 40px;
    }
    .header-title {
      flex: 1;
      padding: 0 10px;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .follow-button {
      background-color: #08152d;
      color: white;
      padding: 6px 25px;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
  .tab-container {
    display: flex;
    margin-top: 20px;
    border-bottom: 1px solid #e7e7e7;
    transition: all 200ms ease-in-out;
    .tab-item {
      flex: 1;
      text-align: center;
      padding-bottom: 10px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #08152d;
      }
    }
  }
  .fragment-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    .profile-fragment {
      flex: 1;
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .item-group {
        .header {
          font-weight: 600;
          font-size: 1.3rem;
          margin-bottom: 20px;
        }
        .description {
          font-size: 0.8rem;
        }
        .item-row {
          display: flex;
          margin-bottom: 20px;
          .item-label {
            flex: 1;
            overflow: hidden;
            max-lines: 1;
            text-overflow: ellipsis;
            padding-right: 20px;
          }
          .item-value {
            color: #292934;
            opacity: 0.5;
          }
        }
        .acton-container {
          display: flex;
        }
        .action-item {
          border: 1px solid #e7e7e7;
          flex: 1;
          text-align: center;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .license-details {
      color: #08152d;
      display: flex;
      flex-direction: column;
      height: 100%;
      .active-license-container {
        .header-container {
          display: flex;
          justify-content: space-between;
          .active-license {
            font-weight: bold;
            font-size: 1rem;
          }
          .all-button {
            text-decoration: underline;
          }
        }
        .active-item {
          display: flex;
          margin: 0 30px;
          align-items: center;
          border: 0.5px solid #ebebeb;
          padding: 0 15px;
          height: 55px;
          .license-icon {
            width: 25px;
            height: 25px;
          }
          .license-name {
            flex: 1;
            padding: 0 15px;
            font-size: 0.9rem;
          }
          .status-icon {
            height: 25px;
            width: 25px;
          }
          .red-dot {
            height: 20px;
            width: 20px;
            background-color: #f44336;
          }
        }
      }
      .licenses-types-container {
        display: flex;
        border-bottom: 1px solid #ebebeb;
        margin-top: 40px;
        overflow-y: auto;
        margin-left: 30px;
        .type-item {
          flex: 1;
          text-align: center;
          font-weight: bold;
          font-size: 1.4rem;
          padding-bottom: 10px;
          cursor: pointer;
          &.active {
            border-bottom: 2px solid #08152d;
          }
        }
      }
      .all-products-list {
        padding: 0 30px;
        margin-top: 30px;
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        .empty-text {
        }
      }
    }
  }
}

.product-item {
  border: 1px solid #ebebeb;
  padding: 25px 20px;
  margin-bottom: 20px;
  .product-header {
    display: flex;
    align-items: center;
    .product-icon {
      width: 25px;
      height: 25px;
    }
    .product-name {
      flex: 1;
      font-weight: 600;
      padding: 0 15px;
    }
    i {
      font-size: 1.2rem;
    }
  }
  .desc {
    margin-top: 10px;
    font-size: 0.8rem;
  }
  .details-container {
    margin-top: 20px;
    .more-header {
      text-decoration: underline;
      font-weight: 600;
    }
    .product-currency {
      font-weight: 600;
    }
    .full-desc {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 0.8rem;
    }
    .values-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .value-title {
      }
      .value {
        font-weight: 600;
      }
    }
    .actions-container {
      display: flex;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -25px;
      .action-filled {
        flex: 1;
        background-color: #010b1e;
        color: white;
        font-weight: bold;
        padding: 10px;
        font-size: 0.8rem;
        text-align: center;
        cursor: pointer;
      }
      .action-outlined {
        flex: 1;
        color: #010b1e;
        background-color: white;
        font-weight: bold;
        padding: 10px;
        font-size: 0.8rem;
        text-align: center;
        border-top: 1px solid #ebebeb;
        cursor: pointer;
      }
    }
  }
}
