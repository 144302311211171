.registration {
  background-color: white;
  flex: 1;
  display: flex;
  color: $primary-color;
  position: relative;
}

.registration-form-view {
  // flex-grow: 1;
  // height: 0;
  .register-component {
    width: 450px;
    .header {
      color: white;
      font-weight: bold;
    }
    .sub-header {
      color: #999c9a;
      text-align: center;
    }
    .options-list {
      margin-top: 20px;
      .option-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 8px;
        justify-content: center;
        padding: 10px 0;
        margin-bottom: 15px;
        .option-icon {
          width: 25px;
          margin-right: 10px;
        }
        .option-name {
          font-weight: 600;
        }
      }
    }
    .form-container {
      margin-top: 3rem;
      cursor: pointer;
    }

    .resend-btn {
      font-weight: 600;
    }

    .input-container {
      border: 1px solid $border-color;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .input-field {
        flex: 1;
        margin-right: 10px;
        background: transparent;
        border: none;
        padding: 8px 4px;
        color: $primary-color;
        &::placeholder {
          color: #878788;
        }
      }
      .otp-container {
        display: flex;
        width: 100%;
        div {
          flex: 1;
          div {
            &:nth-child(1) {
              flex: 1;
            }
          }
        }
      }
      .otp-input {
        font-weight: bold;
        font-size: 2rem;
        border: none;
        border-bottom: 1px solid $primary-color;
      }
      .input-action {
        cursor: pointer;
        .input-action-icon {
          width: 20px;
        }
      }
      .validator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #d80027;
      }
    }

    .profile-image-container {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      border: 1px solid white;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      .profile-image {
        width: 150px;
        height: 150px;
        object-fit: fill;
        z-index: 0;
      }
      .image-uploader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        padding: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
    .next-btn {
      background: $primary-color;
      padding: 10px;
      font-size: 1rem;
      color: #ffffff;
      margin-top: 1rem;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      font-weight: bold;
    }
  }
}
