.action-fiat-funding-list {
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    align-items: center;
    .coin-logo {
      width: 30px;
      height: 30px;
    }
    .coin-name {
      flex: 1;
      padding: 0 15px;
      font-weight: bold;
      font-size: 18px;
    }
    .no-txns {
      font-size: 15px;
    }
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    margin-top: 20px;
    .list-item {
      margin-bottom: 15px;
      border: 1px solid #eeeeee;
      .item-details {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-values {
          .item-amount {
            font-size: 20px;
            font-weight: 600;
          }
          .item-date {
            font-size: 10px;
          }
        }
        .item-status {
          border: 1px solid #eeeeee;
          padding: 7px 20px;
        }
      }
      .item-banker-container {
        display: flex;
        background-color: #f1f4f6;
        justify-content: space-between;
        padding: 10px 20px;
        .banker-title {
        }
        .baker-name {
        }
      }
    }
  }
}
