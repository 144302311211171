.gx-token-wrapper {
  width: 100%;
  .gxt-wallet-wrapper {
    display: flex;
    .wallet-wrapper {
      display: flex;
      flex-direction: column;
      .gxt-balance {
        color: #010b1e;
        span {
          font-size: 50%;
        }
      }
      .market-cap-value {
        color: #010b1e;
        font-weight: bold;
      }
      .market-cap-title {
        color: #010b1e;
        font-weight: lighter;
        margin-bottom: 0;
      }
    }
    .wallet-action-wrapper {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .action-buttons {
        display: flex;
        flex-direction: row;
        .gxt-btn {
          border: 1px solid #010b1e;
          color: #010b1e;
          font-size: 18px;
          padding: 3px 15px;
          border-radius: 10px;
          margin-right: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.primary {
            color: #212020;
            border: none;
            background: linear-gradient(270deg, #ffff -35.9%, #010b1e 141.3%);
          }
        }
      }
      .gtx-market-wrapper {
        display: flex;
        margin-top: auto;
        justify-content: space-between;
        .gx-market {
          .market-cap-value {
            color: #010b1e;
            font-weight: bold;
          }
          .market-cap-title {
            color: #010b1e;
            font-weight: lighter;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.market-limit-wrapper {
  text-align: end;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @include sm {
    margin-top: 3rem;
  }
  .market-limit-header {
    color: #010b1e;
    text-align: start;
    span {
      color: #4a4a4a;
    }
  }
  .market-from-wrapper {
    margin-top: auto;
    @include sm {
      margin-top: 20px;
    }
    .market-form {
      margin-bottom: 10px;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      .market-input {
        flex-grow: 1;
        border: 1px solid #2a2e39;
        color: #010b1e;
        padding: 8px 12px;
        margin-right: 10px;
        min-width: 250px;
      }
      .submit-btn {
        border: 1px solid #2a2e39;
        color: #010b1e;
        padding: 8px 15px;
        background-color: white;
      }
    }
  }
}

.trading-view-wrapper {
  height: 100%;
  @include sm {
    height: 500px;
  }
}

.buy-sell-table-wrapper {
  display: flex;
  flex-direction: column;
  @include sm {
    margin-top: 3rem;
  }
  .table-header-wrapper {
    display: flex;
    justify-content: space-between;
    background: #1c2030;
    padding: 8px 10px;
    .txn-type-wrapper {
      display: flex;
      .txn-type {
        color: #010b1e;
        font-size: 14px;
        line-height: 16px;
      }
      .sell-txn {
        color: white;
        font-size: 14px;
        line-height: 16px;
      }
      .txn-separator {
        color: white;
        font-size: 14px;
        line-height: 16px;
        margin-left: 6px;
        margin-right: 6px;
      }
    }
    .period-wrapper {
      display: flex;
      .period {
        color: white;
        font-size: 14px;
        line-height: 16px;
        padding-left: 15px;
        font-weight: bold;
      }
    }
  }
  .sales-table-wrapper {
    margin-top: 25px;
    padding: 8px 10px;
    flex-grow: 1;
    height: 0;
    overflow: auto;
    padding-top: 0;
    @include sm {
      height: 100%;
    }
    .sales-table {
      width: 100%;
      border: none;
      position: relative;
      background-color: transparent !important;
      thead {
        tr {
          th {
            color: white;
            font-size: 13px;
            padding: 10px 5px;
            position: sticky;
            top: 0;
            background-color: #030f22;
            border-bottom: 1px solid #2a2e39;
          }
          .price-header {
            text-align: end;
          }
          .amount-header {
            text-align: end;
          }
        }
      }
      tbody {
        overflow-y: auto;
        tr {
          border-bottom: 1px solid #2a2e39;
          td {
            padding: 10px 5px;
          }
          &:last-child {
            border-bottom: none;
          }
          .time-value {
            font-size: 13px;
            color: #010b1e;
          }
          .price-value {
            color: #ef5350;
            font-size: 13px;
            text-align: end;
          }
          .amount-value {
            color: #010b1e;
            font-size: 13px;
            text-align: end;
          }
        }
      }
    }
  }
}
