.broker-app-chat-wrapper {
  height: 100%;
  z-index: 1;
  min-width: 20%;
  background: white;
  transition: all 0.2s linear;
  position: relative;
  @include sm {
    display: none;
  }
}

.drag-handle {
  height: 100%;
  width: 5px;
  z-index: 5;
  margin-right: -5px;
  cursor: col-resize;
  // background: red;
  position: relative;
}
.broker-chat {
  height: 100%;
  background: #ffffff;
  border-left: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  .chat-container {
    display: flex;
    flex: 1;
    .chat-wrapper {
      width: 100%;
      flex-direction: column;
      hr {
        background: #eeeeee;
      }
      .chat-header-wrapper {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 190px;
        .expander-button {
          position: absolute;
          left: -35px;
          transition: all 200ms ease;
          transform: rotate(0deg);
          .expand-icon {
            width: 30px;
            height: 30px;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
          }
        }
        .rotate {
          transform: rotate(180deg);
        }
        .poweredBy {
          display: flex;
          margin: 0;
          align-items: center;
          font-size: 12px;
          color: white;
          background: $primary-color;
          font-weight: 500;
          padding: 8px 30px;
          padding-right: 20px;
          width: 100%;
          height: 60px;
          img {
            margin-left: 7px;
            filter: brightness(0) invert(1);
            height: 14px;
          }
          .switchBtns {
            margin-left: auto;
          }
          .switch {
            margin-left: auto;
            display: flex;
            height: 25px;
            align-items: center;
            span {
              font-size: 12px;
              font-weight: 600;
            }
            .switchWrap {
              margin: 0 3px;
              height: 16px;
              width: 30px;
              border-radius: 8px;
              border: 2px solid white;
              background: none;
              padding: 2px;
              display: flex;
              .switchBall {
                background: white;
                height: 8px;
                width: 8px;
                border-radius: 5px;
                margin-left: 0;
                margin-right: auto;
                &.true {
                  margin-left: auto;
                  margin-right: 0;
                }
              }
            }
          }
        }

        .user-details {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          .logo-container {
            margin: auto 0;
          }
          .header-logo {
            height: 55px;
            width: 230px;
            margin-right: auto;
          }
          .chats-io-logo {
            height: 18px;
            margin-left: 20px;
          }

          .expand-toggle {
            border: 1px solid $primary-color;
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            cursor: pointer;
            @include hover-zoom(1.1);
            transition: all 0.2s linear;
            margin-right: 15px;
            .arrow-icon {
              width: 11px;
              height: 11px;
            }
          }

          .user-designation {
            color: #65666b;
            text-decoration: none;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
        .close-icon {
          width: 16px;
          height: 16px;
          margin-top: auto;
          margin-bottom: auto;
          cursor: pointer;
        }
      }
      #chat-area {
        scroll-behavior: smooth;
      }
      .chat-area-wrapper {
        overflow-y: auto;
        flex-grow: 1;
        height: 0;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 10px;
        margin-top: 10px;
        position: relative;
        .answer {
          margin: 0 5%;
          margin-top: 5%;
          display: flex;
          .bot {
            width: 70px;
            display: flex;
            flex-direction: column;
            .img-logo {
              margin: 0 auto;
              background-color: $primary-color;
              height: 50px;
              width: 50px;
              border-radius: 25px;
            }
            p {
              text-align: center;
              font-size: 11px;
            }
          }
          .question-wrap {
            margin: auto 8px auto auto;
            padding: 16px;
            min-width: 20%;
            max-width: 60%;
            height: fit-content;
            background: white;
            border: 1px solid $border-color;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            flex-direction: column;
            .question-text {
              width: 100%;
              font-size: 12px;
              word-break: break-word;
              margin: 0;
            }
            .preview {
              width: 100%;
              height: fit-content;
              position: relative;
              svg {
                width: 100%;
                height: auto;
              }
              .previewItem {
                object-fit: contain;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                &.file {
                  padding: 35px;
                }
              }
            }
          }
          .question-text {
            font-size: 12px;
            word-break: break-word;
          }
        }
        .question {
          margin: 0 4%;
          margin-top: 5%;
          display: flex;
          .bot {
            width: 70px;
            display: flex;
            flex-direction: column;
            .app-logo-container {
              margin: 0 auto;
              height: 50px;
              width: 50px;
              background: $primary-color;
              border-radius: 25px;
              padding: 10px;
              display: flex;
            }
            .app-logo {
              margin: auto;
              flex: 1;
            }
            .img-logo {
              margin: 0 auto;
              background-color: $primary-color;
              height: 50px;
              width: 50px;
              border-radius: 25px;
            }
            p {
              text-align: center;
              font-size: 11px;
            }
          }
          .question-wrap {
            margin: auto auto auto 8px;
            padding: 16px;
            min-width: 20%;
            max-width: 60%;
            background: #e8eaef50;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            flex-direction: column;
            .question-text {
              width: 100%;
              font-size: 12px;
              word-break: break-word;
              margin: 0;
            }
            .preview {
              width: 100%;
              height: fit-content;
              position: relative;
              svg {
                width: 100%;
                height: auto;
              }
              .previewItem {
                object-fit: contain;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                &.file {
                  padding: 35px;
                }
              }
            }
          }
          .question-loading {
            width: 100px;
            height: 40px;
            margin-left: 16px;
            margin-right: auto;
            padding: 0 12px;
            display: flex;
            align-items: center;
            background: #e8eaef50;
            font-size: 14px;
            border-radius: 30px;
            .lds-ellipsis {
              display: inline-block;
              position: relative;
              width: 60px;
              height: 20px;
              div {
                position: absolute;
                top: 6px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: rgb(177, 177, 177);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                &:nth-child(1) {
                  left: 8px;
                  animation: lds-ellipsis1 0.6s infinite;
                }

                &:nth-child(2) {
                  left: 8px;
                  animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(3) {
                  left: 32px;
                  animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(4) {
                  left: 56px;
                  animation: lds-ellipsis3 0.6s infinite;
                }
              }
            }

            @keyframes lds-ellipsis1 {
              0% {
                transform: scale(0);
              }

              100% {
                transform: scale(1);
              }
            }

            @keyframes lds-ellipsis3 {
              0% {
                transform: scale(1);
              }

              100% {
                transform: scale(0);
              }
            }

            @keyframes lds-ellipsis2 {
              0% {
                transform: translate(0, 0);
              }

              100% {
                transform: translate(24px, 0);
              }
            }
          }
        }
        .chats {
          padding: 0 10px;
        }
        .in-chat-info {
          display: flex;
          padding: 5px;
          span {
            margin: auto;
            color: lightgrey;
            font-style: italic;
            font-size: 10px;
          }
        }

        .typing-indicator {
          display: flex;
          text-align: justify;
          color: grey;
          margin-left: 20px;
          position: absolute;
          bottom: 70px;
          left: 10px;
          .ticontainer {
            .tidot {
              background-color: lightgray;
              border-radius: 20px;
              display: inline-block;
              height: 10px;
              width: 10px;
              margin-right: 2px;
              margin-top: 5px;
              margin-bottom: 2px;
              animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
              &:nth-child(1) {
                animation-delay: 200ms;
              }
              &:nth-child(2) {
                animation-delay: 300ms;
              }
              &:nth-child(3) {
                animation-delay: 400ms;
              }
            }
          }
        }
        .message-item-wrapper {
          display: flex;
          max-width: 80%;
          margin-top: 10px;
          .message-body {
            padding: 10px 15px;
            max-width: 100%;
            overflow-wrap: break-word;
          }
        }
        .sent-message {
          margin-left: auto;
          justify-content: flex-end;
          .message-body {
            background: #010b1e;
            color: #ffffff;
            text-align: end;
            border-radius: 10px 10px 0 10px;
          }
        }
        .received-message {
          margin-right: auto;
          justify-content: flex-start;
          .message-body {
            background: #e2e2e2;
            color: #65666b;
            text-align: start;
            border-radius: 10px 10px 10px 0;
          }
        }
      }
      .chat-input-area {
        border-top: 1px solid #eeeeee;
        padding: 0 15px;
        position: relative;
        background-color: white;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -20px;
        .chat-input-form {
          display: flex;
          margin-top: 22px;
          margin-bottom: 22px;
          .chat-input {
            flex-grow: 1;
            width: 0;
            margin-left: 10px;
            font-size: 16px;
            background: transparent;
            border: none;
            outline: none;
            color: #010b1e;
          }
          .chat-submit-btn {
            background: transparent;
            border: none;
            outline: none;
          }
        }

        .chats-action-wrapper {
          position: absolute;
          left: -60px;
          top: 0;
          background: #ffffff;
          border-radius: 15px 0px 0px 15px;
          border: 1px solid #eeeeee;
          padding: 12px 18px;
          height: 100%;
          width: 60px;
          cursor: pointer;
          display: flex;
          .chats-icon {
            margin: auto;
            width: 100%;
          }
        }

        .chat-action-opened-wrapper {
          display: flex;
          justify-content: space-evenly;
          background: #ffffff;
          margin-right: -15px;
          margin-left: -15px;
          padding-top: 16px;
          padding-bottom: 16px;
          .chat-action {
            display: flex;
            cursor: pointer;
            img {
              margin: auto;
              width: 22px;
              height: 22px;
            }
          }
          .chat-close-action-wrapper {
            width: 65px;
            position: relative;
            margin-bottom: -8px;
            .chat-close-action {
              cursor: pointer;
              position: absolute;
              bottom: -10px;
              border-radius: 50%;
              border: 1px solid #186ab4;
              padding: 14px;
              background-color: white;
              img {
                margin-right: 2px;
                width: 35px;
                height: 35px;
              }
            }
          }
        }
        .action-suggested-wrapper {
          position: absolute;
          top: -35px;
          z-index: 1;
          width: 100%;
          margin-right: -15px;
          margin-left: -15px;
          padding-left: 11px;
          padding-right: 11px;
          .action-list {
            display: flex;
            width: 100%;
            overflow-x: auto;
            &::-webkit-scrollbar {
              height: 0;
              width: 0;
            }
            .suggested-action {
              background: #ffffff;
              border-radius: 5px;
              color: #186ab4;
              font-size: 15px;
              padding: 4px 15px;
              text-align: center;
              cursor: pointer;
              min-width: 70px;
              height: 30px;
              margin-right: 10px;
              margin-left: 4px;
              box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
                0 3px 6px rgba(0, 0, 0, 0.23);
              &:last-child {
                margin-left: 0;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .chat-loading-wrapper {
        display: flex;
        margin: auto;
      }
      .emoji-picker-wrapper {
        position: absolute;
        bottom: 60px;
        right: 20px;
      }
    }
  }
  .chatInputs {
    display: flex;
    border-top: 1px solid $border-color;
    align-items: flex-end;
    .addFile,
    .sendBtn {
      height: 60px;
      width: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      cursor: pointer;
      border-right: 1px solid $border-color;

      img {
        width: 40%;
        height: auto;
      }
    }
    .sendBtn {
      background: #010b1e;
      cursor: pointer;
    }
    .textArea {
      padding: 6px;
      line-height: 1;
      min-height: 60px;
      max-height: 130px;
      height: 100%;
      width: 0;
      flex: 1;
      border: none;
      display: flex;
      font-size: 15px;
      //   align-items: center;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0px);
  }
}

.one-one-chat-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 30px;
  color: $primary-color;
  .search-container {
    margin-top: 20px;
    display: flex;
    border: 1px solid $border-color;
    padding: 10px 20px;
    .search-icon {
      width: 20px;
      height: 20px;
    }
    .search-input {
      flex: 1;
      padding: 0 10px;
    }
  }
  .header {
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 3rem;
  }
  .recent-chats-container {
    display: flex;
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
    .recent-chat-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border: 1px solid $border-color;
      margin-right: 15px;
      cursor: pointer;
      .avatar-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 5px;
      }
      .name {
        text-align: center;
        font-weight: 600;
        text-transform: capitalize;
      }
      .time {
        text-align: center;
        font-size: 0.8rem;
        color: #bbbbbb;
      }
    }
  }
  .friends-list-container {
    margin-top: 20px;
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    .friend-item {
      display: flex;
      align-items: center;
      padding: 20px;
      border: 1px solid $border-color;
      margin-bottom: 15px;
      cursor: pointer;
      .avatar-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .name {
        font-weight: 600;
        text-transform: capitalize;
      }
      .time {
        font-size: 0.8rem;
        color: #bbbbbb;
      }
    }
  }
}
.chat-layout-container {
  .chat-header {
    display: flex;
    background-color: $primary-color;
    border-top: 1px solid white;
    align-items: center;
    padding: 20px 10px;
    .chat-back-button {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 20px;
    }
    .chat-user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .chat-user-container {
      margin-left: 10px;
      flex: 1;
      .chat-user-name {
        color: white;
        font-weight: 600;
      }
      .chat-user-bio {
        color: white;
        font-size: 0.7rem;
      }
    }
  }
}
