.profile-user-details-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .user-item {
    display: flex;
    align-items: center;
    height: 150px;
    cursor: pointer;
    padding-left: 50px;
    .user-details {
      margin-left: 25px;
      display: flex;
      flex-direction: column;
      .user-name {
        font-weight: 700;
        font-size: 45px;
        text-transform: capitalize;
        transition: all 200ms ease-in-out;
      }
      .user-email {
        opacity: 0.5;
        font-size: 18px;
        transition: all 200ms ease-in-out;
      }
    }
  }
}

.user-stats-details-wrapper {
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  width: 40%;
  overflow: hidden;
  .user-stats-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    padding-bottom: 10px;
  }
  .details-title {
    font-weight: 700;
    font-size: 40px;
    span {
      text-transform: capitalize;
    }
  }
  .details-sub-title {
    opacity: 0.5;
    font-size: 16px;
  }
  .search-container {
    display: flex;
    border: 1px solid $border-color;
    margin: 40px 0;
    align-items: center;
    input {
      flex: 1;
      height: 60px;
      font-size: 13px;
      padding: 0 20px;
    }
    .search-icon {
      width: 18px;
      height: 18px;
    }
    .selected-option {
      border-left: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 100%;
      @include hover-zoom(1.1);
      .option-img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: hidden;
    .empty-message {
      text-align: center;
      font-weight: 700;
      width: 100%;
      margin-top: 50px;
      font-size: 1.5rem;
    }
    .list-item {
      width: 33.333%;
      margin-bottom: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      @include hover-zoom(1.05);
    }
    .user-name {
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .action-button {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    .action-icon {
      height: 30px;
    }
  }
}
