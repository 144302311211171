.licenses-bots-wrapper {
  display: flex;
  flex-direction: column;
  .view-wrapper {
    flex: 1;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
  }
  .choice-group {
    .choice-header {
      text-transform: capitalize;
    }
    .choice-list {
      .choice-item {
        height: 100px;
        width: 100px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .choice-icon {
          width: 40px;
          height: 40px;
          margin: 0 30px;
        }
        .choice-name {
          max-lines: 1;
          font-size: 10px;
        }
      }
    }
    .licenses-list-container {
      margin-top: 20px;
      .search-container {
        display: flex;
        border: 1px solid #e7e7e7;
        align-items: center;
        .search-input {
          flex: 1;
          font-size: 0.75rem;
          padding: 0 15px;
        }
        .search-action {
          border-left: 1px solid #e7e7e7;
          padding: 10px 15px;
          font-weight: 500;
          font-size: 0.65rem;
        }
      }
      .list-empty-text {
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
        width: 100%;
      }
      .list-container {
        display: flex;
        width: 100%;
        overflow-x: auto;
        margin-top: 30px;
        padding-bottom: 20px;
        &::-webkit-scrollbar {
          height: 8px;
        }
      }
    }
  }
}

.license-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .filter-container {
    display: flex;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin: 0 -20px;
    padding: 10px 20px;
    .filter-item {
      border: 0.5px solid #e7e7e7;
      padding: 5px 20px;
      margin-right: 10px;
      font-size: 11px;
      cursor: pointer;
      width: 90px;
      display: flex;
      justify-content: center;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }
  .error-message-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
  }
  .view-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    .user-container {
      padding: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      .back-button {
        cursor: pointer;
        width: 25px;
        height: 25px;
        padding: 3px;
      }
    }
    .license-details {
      font-size: 12px;
      margin-bottom: 20px;
    }
    .list-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      .list {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        .product-group-item {
          display: flex;
          align-items: center;
          border: 1px solid $border-color;
          padding: 20px 25px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          cursor: pointer;
          .product-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .product-name {
            font-weight: 500;
          }
        }
        .license-purchase-item {
          border: 1px solid $border-color;
          padding: 20px 25px;
          margin-bottom: 20px;
          position: relative;
          .row-group {
            display: flex;
            justify-content: space-between;
          }
          .item-name {
            font-weight: 600;
            font-size: 15px;
          }
          .item-date {
            font-weight: 600;
            font-size: 14px;
            text-align: end;
          }
          .billing-cycle {
            font-size: 11px;
            margin-top: 2px;
          }
          .date-label {
            text-align: end;
            font-size: 11px;
            margin-top: 2px;
          }
          .status-ribbon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 8px;
          }
        }
      }
    }
  }
}

.license-item {
  border: 1px solid #ebebeb;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  .license-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .license-name {
      font-weight: bold;
      font-size: 1.1rem;
      padding-right: 20px;
    }
    .status-icon {
      width: 25px;
      height: 25px;
    }
  }
  .details-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .detail-label {
      font-weight: 500;
      font-size: 12px;
    }
    .detail-value {
      font-size: 12px;
      text-align: right;
    }
  }
  .actions-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .outline-button {
      border: 1px solid #ebebeb;
      width: 130px;
      margin-right: 15px;
      text-align: center;
      font-size: 10px;
      padding: 10px;
      cursor: pointer;
    }
    .filled-button {
      color: white;
      width: 130px;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      padding: 10px;
      cursor: pointer;
      background-color: #08152d;
    }
  }
}
