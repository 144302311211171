.endorsement-brands-wrapper {
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  color: $primary-color;
  .header-container {
    display: flex;
    overflow: hidden;
    .header-logo {
      height: 45px;
    }
    .header-text {
      margin-top: 20px;
      font-weight: 400;
      color: $primary-color;
      font-size: 16px;
    }
    .search-input {
      flex: 1;
      padding: 0 30px;
    }
    .selected-option {
      display: flex;
      align-items: center;
      height: 65px;
      padding: 0 20px;
      border-left: 1px solid $border-color;
      @include hover-zoom(1.05);
      .option-img {
        width: 25px;
        height: 25px;
      }
      .option-name {
        font-weight: 700;
        font-size: 1.05rem;
        margin-left: 10px;
      }
    }
  }
  .fragment-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    .frag-header-container {
      display: flex;
      height: 90px;
      .active-category {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        border-right: 1px solid $border-color;
        cursor: pointer;
        @include hover-zoom(1.1);
        .active-category-img {
          height: 35px;
          width: 35px;
        }
        .active-brand-name {
          margin-left: 10px;
          font-weight: 700;
          font-size: 18px;
        }
      }
      .search-container {
        flex: 1;
        display: flex;
        padding: 0 30px;
        .search-input {
          flex: 1;
          font-size: 15px;
          color: $primary-color;
          &::placeholder {
            text-transform: capitalize;
          }
        }
      }
      .header-action-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        border-left: 1px solid $border-color;
        cursor: pointer;
        &:hover {
          .action-icon {
            transform: scale(1.15);
          }
        }
        .action-icon {
          width: 25px;
          height: 25px;
          transition: all 200ms ease-in-out;
        }
      }
    }
  }
  .frag-tab-container {
    display: flex;
    border-bottom: 2px solid #e5e9f2;
    border-top: 1px solid #e5e9f2;
    height: 60px;
    .tab-item {
      height: 60px;
      color: #8391a7;
      font-weight: 300;
      cursor: pointer;
      margin-bottom: -2px;
      font-size: 0.8rem;
      margin-right: 20px;
      min-width: 100px;
      text-align: center;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      @include hover-zoom(1.05);
      &.active {
        color: #1c2c40;
        font-weight: 500;
        border-bottom: 2px solid #182542;
        font-size: 0.95rem;
        transform: none;
      }
    }
    .tab-actions-container {
      display: flex;
      margin-left: auto;
      .tab-action {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $border-color;
        width: 55px;
        &.close {
          .tab-action-icon {
            height: 18px;
          }
        }
        .tab-action-icon {
          height: 20px;
          @include hover-zoom(1.1);
        }
      }
    }
    .search-input {
      padding: 0 30px;
      font-size: 0.9rem;
      flex: 1;
    }
  }
  .footer-container {
    background-color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    .footer-text {
      color: white;
      font-weight: 700;
      font-size: 20px;
      margin-right: 10px;
    }
    .footer-logo {
      height: 24px;
    }
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border-color;
    .profile-details-container {
      display: flex;
      align-items: center;
      padding: 30px 50px;
      .profile-details {
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .user-name {
          font-weight: 700;
          font-size: 40px;
          text-transform: capitalize;
        }
        .user-stats {
          color: #7b7b7b;
          font-weight: 500;
          font-size: 16px;
          text-decoration: underline;
          @include hover-zoom(1.05);
        }
      }
      .user-stats-container {
        display: flex;
        .user-stats-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          border: 1px solid $border-color;
          margin-left: 10px;
          width: 100px;
          height: 55px;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          &:hover {
            background-color: $primary-color;
            .stats-value {
              color: white;
            }
            .stats-name {
              font-weight: 700;
              color: white;
            }
          }
          .stats-value {
            text-align: center;
            font-weight: 700;
            font-size: 22px;
          }
          .stats-name {
            text-align: center;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.brands-carousel {
  width: 100%;
  margin-top: 30px;
  .brand-item-wrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .brand-item-container {
      width: 300px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      .brand-item {
        margin-top: auto;
        width: 250px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 100ms linear;
        @include hover-zoom(1.05);
        &.active {
          height: 125px;
          transform: none;
          width: 300px;
        }
        .brand-logo {
          width: 30px;
        }
        .brand-name {
          color: white;
          font-weight: 700;
          margin-left: 10px;
          font-size: 1.1rem;
        }
      }
    }
  }
  .loading-skelton {
    display: flex;
    .brand-skeleton {
      flex: 0 0 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 110px;
      margin: 0 15px;
      border: 1px solid $border-color;
      .brand-name-skelton {
        margin-left: 10px;
      }
    }
  }
}

.products-list-container {
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  border-top: 1px solid $border-color;
  .empty-text {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .product-item {
    display: flex;
    flex: 0 0 33.33%;
    margin-bottom: 50px;
    align-items: center;
    height: 130px;
    border: none;
    cursor: pointer;
    @include hover-zoom(1.025);
    .app-icon-container {
      border-radius: 8px;
      width: 90px;
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.123);
      .app-icon {
        width: 80%;
        height: 80%;
      }
    }
    .app-details {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .app-name {
        font-weight: 700;
        font-size: 1.1rem;
      }
      .app-desc {
        font-weight: 300;
        color: rgba(0, 0, 0, 0.47);
        font-size: 0.8rem;
        margin-bottom: 8px;
      }
      .action-container {
        display: flex;
        .action {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid $border-color;
          margin-right: 10px;
          border-radius: 6px;
          font-size: 11px;
          font-weight: 500;
          height: 25px;
          cursor: pointer;
          color: $primary-color;
          font-weight: 700;
          @include hover-zoom(1.1);
          &.primary {
            color: white;
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.user-stats-popup-container {
  color: $primary-color;
  height: 700px;
  display: flex;
  flex-direction: column;
  .header-container {
    background-color: white !important;
    border-bottom: 1px solid $border-color;
    padding: 25px !important;
    .header-logo {
      height: 35px;
    }
  }
  .modal-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .search-container {
    margin: 25px 40px;
    border: 1px solid $border-color;
    display: flex;
    .search-input {
      width: 100%;
      height: 45px;
      padding: 0 30px;
      &::placeholder {
        text-transform: capitalize;
      }
    }
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    .list-item {
      display: flex;
      align-items: center;
      margin: 50px 40px;
      cursor: pointer;
      @include hover-zoom(1.05);
      &:first-child {
        margin-top: 25px;
      }
      &:last-child {
        margin-bottom: 25px;
      }
      .item-icon {
        width: 30px;
        height: 30px;
      }
      .item-name {
        margin-left: 15px;
        flex: 1;
        font-weight: 600;
        text-transform: capitalize;
      }
      .active-icon {
        width: 25px;
        height: 25px;
      }
    }
    .list-empty-text {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      font-weight: 600;
    }
  }
  .control-button-container {
    display: flex;
    margin-top: 10px;
    .control-item {
      flex: 1;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 18px;
      @include hover-zoom(1.05);
      border-top: 1px solid $border-color;
      &.active {
        font-weight: 600;
        color: white;
        background-color: $primary-color;
        transform: none;
      }
    }
  }
  .user-selected-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px;
    align-items: center;
    justify-content: center;
    .profile-avatar {
      margin-top: auto;
    }
    .selected-user-name {
      font-weight: 700;
      font-size: 35px;
      text-align: center;
      margin-top: 30px;
      text-transform: capitalize;
    }
    .selected-user-mail {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      margin-top: 15px;
      margin-bottom: auto;
    }
    .user-redirects-container {
      margin: auto 0;
      display: flex;
      width: 100%;
      .redirect-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-color;
        height: 60px;
        margin-right: 15px;
        @include hover-zoom(1.05);
        &:last-child {
          margin-right: 0;
        }
        .redirect-img {
          height: 25px;
        }
      }
    }
  }
}

.filter-container {
  .filter-item {
    display: flex;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    @include hover-zoom(1.05);
    &.active {
      .item-name {
        font-weight: 600;
      }
    }
    .item-icon {
      width: 40px;
      height: 40px;
    }
    .item-name {
      font-weight: 400;
      margin-left: 10px;
      font-size: 1.1rem;
    }
  }
}

.product-details-wrapper {
  flex: 1;
  padding: 40px 90px;
  border-top: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .product-header-container {
    display: flex;
    align-items: center;
    .product-icon {
      width: 85px;
      height: 85px;
      object-fit: contain;
    }
    .header-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-start;
      margin-left: 20px;
      .product-name {
        font-weight: bold;
        font-size: 34px;
        margin-bottom: 5px;
      }
      .product-sub-title {
        font-size: 16px;
      }
    }
    .action-btn {
      border: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 45px;
      font-weight: 600;
      font-size: 16px;
      @include hover-zoom(1.1);
      &.alt {
        color: white;
        background-color: $primary-color;
      }
    }
  }
  .product-desc {
    font-size: 15px;
    line-height: 35px;
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .licence-details {
    display: flex;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    overflow-y: auto;
    height: 160px;
    padding: 30px 0;
    &::-webkit-scrollbar {
      height: 0;
    }
    .item-container {
      border-right: 1px solid $border-color;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      &.add-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include hover-zoom(1.1);
        .item-title {
          opacity: 0.7;
          margin-top: 10px;
        }
      }
      &:last-child {
        border: none;
      }
      .item-title {
        font-size: 300;
        font-size: 10px;
        text-align: center;
      }
      .item-value {
        font-weight: 700;
        font-size: 23px;
        text-align: center;
      }
      .edit-button {
        position: absolute;
        z-index: 1;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 200ms ease-in-out;
        cursor: pointer;
        &:hover {
          transform: translateY(-50%) scale(1.5);
        }
      }
    }
  }
}

.add-endorsement-modal {
  display: flex;
  flex-direction: column;
  color: $primary-color;
  height: 550px;
  width: 100%;
  .header-container {
    background-color: white;
    padding: 20px 0;
    .header-logo {
      height: 35px;
    }
  }
  .modal-content-container {
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
    flex-grow: 1;
    height: 0;
    overflow: auto;
    width: 100%;
  }
  .title {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .app-list-container {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 50px;
    &::-webkit-scrollbar {
      height: 0;
    }
    .app-item {
      flex: 0 0 75px;
      height: 75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      @include hover-zoom(1.05);

      &.active {
        .icon-container {
          border-color: $primary-color;
        }
        .app-name {
          font-weight: 600;
        }
      }
      .icon-container {
        height: 50px;
        width: 50px;
        border: 1px solid $border-color;
        padding: 6px;
        display: flex;
        position: relative;
        .icon {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .app-name {
        font-weight: 500;
        font-size: 9px;
        text-align: center;
        margin-top: 5px;
        white-space: nowrap;
      }
    }
  }
  .input-container {
    display: flex;
    height: 60px;
    .percentage-input {
      border: 1px solid $border-color;
      height: 100%;
      font-size: 23px;
      padding: 0 15px;
      width: 200px;
    }
    .unit {
      border: 1px solid $border-color;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 23px;
      width: 60px;
    }
  }
  .short-input {
    border: 1px solid $border-color;
    height: 60px;
    width: 500px;
    padding: 10px 20px;
  }
  .long-input {
    border: 1px solid $border-color;
    padding: 10px 20px;
    width: 500px;
    height: 200px;
    resize: none;
  }
  .edit-components {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    width: 100%;
    padding: 0 30px;
    &.scroll {
      flex: none;
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      justify-content: unset;
    }
    .product-images {
      margin-top: 0;
      flex: none;
    }
  }
  .product-images {
    margin-top: 50px;
    flex: 1;
    .images-container {
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      .image-item {
        margin-top: 10px;
        flex: 0 0 200px;
        border: 1px solid $border-color;
        background-image: url(../images/upload-cloud-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .drag-input {
          width: 100%;
          height: 100%;
          opacity: 0;
        }
        .image-preview {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .loading-text {
          text-align: center;
          color: rgba(24, 37, 66, 0.5);
        }
      }
    }
  }

  .scroll-item {
    margin-top: 50px;
  }

  .product-details {
    margin-top: 50px;
    .percentage-input {
      width: 100px;
    }
    .unit {
      width: 100px;
    }
  }

  .ratings-items-container {
    display: flex;
    flex-direction: column;
    .rating-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &.empty-item {
        .rating-number {
          opacity: 0.7;
        }
      }
      .rating-name {
        display: flex;
        align-items: center;
        height: 60px;
        border: 1px solid $border-color;
        width: 260px;
        padding: 0 20px;
        font-size: 14px;
        .rating-icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
      .rating-number {
        font-size: 14px;
      }
      .rating-value {
        font-size: 14px;
        width: 250px;
      }
      .rating-count {
        width: 100px;
      }
      .rating-max {
        width: 100px;
      }
    }
  }
  .website-input {
    border: 1px solid $border-color;
    height: 60px;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .web-input {
      height: 100%;
      flex: 1;
      margin-right: 15px;
    }
    .pate-icon {
      width: 20px;
      height: 20px;
      @include hover-zoom(1.1);
    }
  }

  .close-btn {
    border-radius: 50%;
    position: absolute;
    top: -11px;
    right: -11px;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid $border-color;
    @include hover-zoom(1.15);
    .close-icon {
      width: 10px;
      height: 10px;
    }
  }
  .complete-btn {
    background-color: $primary-color;
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    padding: 15px;
    @include hover-zoom(1.05);
  }
  .completed-text {
    text-align: center;
    font-size: 15px;
    padding: 0 60px;
    margin-top: 40px;
  }
  .center-logo {
    height: 65px;
    animation: leaves 0.75s infinite alternate;
  }
}

.edit-endorsement-modal {
  display: flex;
  flex-direction: column;
  color: $primary-color;
  height: 550px;
  align-items: center;

  .tabs-container {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $border-color;
    overflow: hidden;
    .tab-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 50px;
      font-weight: 600;
      opacity: 0.5;
      font-size: 12px;
      @include hover-zoom(1.1);
      &.active {
        font-weight: 700;
        opacity: 1;
        font-size: 14px;
        border-bottom: 1px solid $primary-color;
        transform: none;
      }
    }
  }

  .product-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid $primary-color;
    object-fit: cover;
    margin-top: auto;
  }
  .product-name {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    margin-top: 20px;
  }
  .app-name {
    text-align: center;
    font-size: 14px;
  }
  .input-title {
    font-weight: 700;
    font-size: 14px;
  }
  .input-row {
    display: flex;
    margin-bottom: auto;
    margin-top: 40px;
    width: 100%;
    .input-container {
      display: flex;
      height: 50px;
      .percentage-input {
        border: 1px solid $border-color;
        height: 100%;
        font-size: 20px;
        padding: 0 15px;
        flex-grow: 1;
        width: 0;
      }
      .unit {
        border: 1px solid $border-color;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        width: 60px;
      }
    }
  }
  .confirm-message {
    text-align: center;
    margin: auto;
    font-weight: 400;
    font-size: 15px;
    width: 300px;
  }
  .actions-container {
    display: flex;
    width: 100%;
    border-top: 1px solid $border-color;
    .action {
      flex: 1;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-align: center;
      @include hover-zoom(1.05);
      &.alt {
        background-color: $primary-color;
        color: white;
      }
    }
  }
}
