* {
  // user-select: none;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
  }
}

.broker-app-wrapper {
  height: 100vh;
  overflow: hidden;
  @include sm {
    width: 100vw;
    height: auto;
  }
  .broker-app-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    .broker-main-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      width: 0;
      height: 100%;
      transition: all 0.2s linear;
      .broker-app-main-wrapper {
        flex-grow: 1;
        width: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .admin-view-wrapper {
        display: flex;
        border-top: 0.5px solid #e5e5e5;
        padding: 15px 48px;
        align-items: center;
        color: #08152d;
        .header {
          margin-right: 15px;
        }
        .user-img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .user-name {
          flex: 1;
          font-weight: bold;
          text-transform: capitalize;
        }
        .filled-btn {
          background-color: #010b1e;
          color: white;
          font-weight: 600;
          width: 150px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          margin-right: 10px;
          cursor: pointer;
        }
        .outlined-btn {
          border: 0.5px solid #e5e5e5;
          font-weight: 600;
          width: 150px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          cursor: pointer;
        }
      }
    }
  }
}

.broker-app-main-wrapper {
  display: flex;
  flex-direction: column;
  .broker-fragment-wrapper {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    height: 0;
    background: white;
    color: #010b1e;
    @include sm {
      padding: 8px;
    }
    .fragment-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      zoom: 0.85;
      @include xxl {
        zoom: 1;
      }
    }
  }
}
