.loading-wrapper {
  width: 22px;
  height: 22px;
  display: inline-block;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
}
.loader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  div {
    box-sizing: border-box !important;
  }
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: 2px solid #000;
    border-color: #fff transparent #fff transparent;
    animation: loader 1s linear infinite;
    &:nth-child(2) {
      border-color: transparent;
      div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          top: -16px;
          left: 66px;
          background: #fff;
          border-radius: 50%;
          box-shadow: 0 164px 0 0 #fff;
        }
        &:after {
          left: -16px;
          top: 66px;
          box-shadow: 164px 0 0 0 #fff;
        }
      }
    }
  }
}

.full-screen-loader {
  background: #010b1e;
  width: 100vw;
  height: 100vh;
  display: flex;
  .full-loading-wrapper {
    margin: auto;
    width: 80px;
    height: 80px;
    display: inline-block;
    overflow: hidden;
  }
  .full-loader {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    div {
      box-sizing: border-box !important;
    }
    & > div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      border: 10px solid #000;
      border-color: #fff transparent #fff transparent;
      animation: loader 1s linear infinite;
      &:nth-child(2) {
        border-color: transparent;
        div {
          position: absolute;
          width: 100%;
          height: 100%;
          transform: rotate(45deg);
        }
      }
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
