.vault-cover-wrapper {
  display: flex;
  align-items: center;
  color: $primary-color;
  padding: 40px 0;
  .cover-value-container {
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    padding: 30px 100px;
    position: relative;
    .cover-value {
      font-weight: 700;
      font-size: 28px;
      text-align: center;
    }
    .cover-value-label {
      font-size: 14px;
      text-align: center;
    }
    .currency-container {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      border: 1px solid $border-color;
      @include hover-zoom(1.1);
      &.left {
        left: 0;
        right: auto;
      }
      .currency-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .affiliate-bank-logo {
    margin-left: auto;
    margin-right: auto;
  }
}

.vault-switcher-container {
  display: flex;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  color: $primary-color;
  .search-container {
    border-left: 1px solid $border-color;
    margin-left: auto;
    .search {
      height: 100%;
      width: 250px;
      padding: 0 20px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .wallet-coin-switcher {
    overflow-x: auto;
    overflow-y: hidden;
    height: 75px !important;
    display: flex;
    flex-grow: 1;
    width: 0;
    &::-webkit-scrollbar {
      height: 0;
    }
    .empty-message {
      font-weight: 700;
      margin: auto;
    }
    .coin-item {
      display: flex;
      align-items: center;
      padding: 15px 25px;
      opacity: 0.25;
      transition: all 200ms ease-in-out;
      justify-content: center;
      cursor: pointer;
      &:hover {
        opacity: 1;
        .coin-icon {
          transform: scale(1.1);
        }
      }
      &.active {
        opacity: 1;
        .coin-icon {
          transform: none;
        }
        .coin-name {
          display: block;
        }
      }
      .coin-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .coin-name {
        font-size: 20px;
        font-weight: bold;
        color: #2a2e39;
        text-align: center;
        display: none;
        margin-left: 10px;
      }
    }
  }
}

.wallet-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .wallet-balance {
    font-weight: 500;
    font-size: 36px;
    span {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .action-button-container {
    display: flex;
    .action-button {
      border: 1px solid #e7e7e7;
      margin-left: 10px;
      padding: 8px;
      width: 120px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.wallet-transaction-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    background: #f0f0f0;
    padding: 10px 0;
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    border: 1px solid #eeeeee;
  }
  .item-header {
    text-align: center;
    font-weight: 700;
  }
  .txn-type {
    flex: 1;
  }
  .txn-date {
    flex: 1;
  }
  .txn-value {
    flex: 1;
  }
  .txn-balance {
    flex: 1;
  }
  .txn-more {
    flex: 1;
  }
  .txn-item {
    display: flex;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
    height: 95px;
  }
  .item-value {
    font-weight: 700;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coin-container {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    margin-left: 10px;
    padding: 5px 10px;
    .coin-icon {
      width: 20px;
      height: 20px;
    }
    .coin-name {
      font-weight: 700;
      margin-left: 5px;
    }
  }
  .action-container {
    display: flex;
    justify-content: center;
    .expand-action {
      border: 1px solid #eeeeee;
      padding: 8px 15px;
      font-size: 12px;
      cursor: pointer;
      font-weight: 700;
      white-space: nowrap;
    }
  }

  .filter-container {
    display: flex;
    border-right: 1px solid $border-color;
    height: 70px;
    .filters-list {
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      .filter-add-btn {
        position: relative;
        color: #464b4e;
        border: 1px solid $border-color;
        border-radius: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
        &.opened {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          margin-right: 25px;
        }
        &:first-child {
          margin-left: 25px;
        }
        .filter-btn {
          font-weight: bold;
          font-size: 11px;
          display: flex;
          align-items: center;
          height: 30px;
          padding-left: 15px;
          @include hover-zoom(1.03);
          .filter-icon {
            margin-right: 5px;
            width: 10px;
            height: 10px;
          }
        }
        .filters-menu {
          position: absolute;
          display: flex;
          flex-direction: column;
          background-color: white;
          top: 30px;
          left: -1px;
          right: -1px;
          border: 1px solid $border-color;
          border-radius: 15px;
          border-top: none;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          .menu-item {
            display: flex;
            align-items: center;
            padding-left: 15px;
            height: 35px;
            @include hover-zoom(1.03);
            &:first-child {
              margin-top: 5px;
            }
            .menu-icon {
              margin-right: 5px;
              width: 10px;
              height: 10px;
            }
            .menu-title {
              font-size: 11px;
              font-weight: bold;
            }
          }
          .remove-btn {
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid $border-color;
            margin-top: 10px;
            @include hover-zoom(1.03);
            &.disabled {
              opacity: 0.4;
              transform: none;
            }
            .menu-icon {
              margin-right: 5px;
              width: 10px;
              height: 10px;
            }
            .menu-title {
              font-size: 11px;
              font-weight: bold;
            }
          }
        }
      }
    }
    .tags-container {
      border-left: 1px solid $border-color;
      padding: 0 30px;
      height: 100%;
      display: flex;
      align-items: center;
      .tags-input {
      }
    }
  }
}

.vault-more-info-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  .header-container {
    display: flex;
    background: #f0f0f0;
    padding: 10px 0;
    justify-content: center;
  }
  .item-header {
    text-align: center;
    font-weight: 700;
  }
  .more-info-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    .coin-name-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #eeeeee;
      align-items: center;
      height: 95px;
      margin-bottom: 30px;
      .coin-image {
        width: 40px;
        height: 40px;
      }
      .coin-name {
        font-size: 30px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
    .value-container {
      display: flex;
      justify-content: space-between;
      padding: 15px 45px;
      .value-label {
      }
      .value {
        font-weight: bold;
      }
    }
    .actions-list-container {
      display: flex;
      flex-wrap: wrap;
      padding: 30px;
      border-top: 1px solid $border-color;
      margin-top: 25px;
      padding-top: 30px;
      .action-item {
        border: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        height: 100px;
        padding: 10px;
        @include hover-zoom(1.1);
        .action-img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        .action-title {
          text-align: center;
          font-weight: 600;
        }
      }
    }
  }
}
