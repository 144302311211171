@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;700;800&display=swap');
.test {
  width: 100%;
  height: 100vh;
  background-color: yellow;
}
.nav_container {
  height: 10vh;
  width: 100%;
  background-color: white;

  display: flex;

  border-bottom: 1px solid #ebebeb;
}
.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}
.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80%;
}
.sidebar {
  width: 0%;
  height: 100vh;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
}
.sidebar2 {
  width: 100%;
  height: 100vh;
  background-color: white;
}
.navIcons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: relative;
  border-right: 1px solid #ebebeb;
}
.navimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 1.9rem;
}
.navimg:first-child {
  margin-top: 0;
  width: 45px;
  height: 45px;
}
.navdetails {
  padding: 20px 20px;
}
.navactiveimg {
  width: 45px;
  height: 45px;
}
.navcover {
  width: 100%;
  height: 40px;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.username {
  font-size: 1.6rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.navcopy {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  opacity: 0.3;
}
.usernamewrap {
  display: flex;
  flex-direction: column;
}
.text {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.text:first-child {
  color: #cf1a38;
  margin-top: 0;
}
.menubox {
  margin-top: 1.9rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login_container {
  width: 100%;
  background-color: white;
  height: 100vh;
  padding: 3rem 2rem 0rem 3.2rem;
}
.login_form {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form_logo {
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 3rem;
}
.form_input {
}
.form_input_wrapper {
  background: transparent;
  border-bottom: 1px solid #d9d9d9;
  color: #0f152c;
  padding: 20px 2px;
  margin-bottom: 3rem;
  width: 100%;
}

.login_button {
  border: 1px solid #0f152c;
  color: #ffffff;
  padding: 15px;
  font-size: 1rem;
  margin-top: 1rem;
  justify-content: center;
  font-weight: 600;
  background-color: #0f152c;
  font-family: 'Montserrat', sans-serif;
  width: 70%;
}

.reset {
  font-weight: 500;
  color: #08152d;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  margin-top: 0.4rem;
  width: 70%;
}
.login_loader_container {
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.login_loader_text {
  font-weight: 600;
  color: #0f152c;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
}
.logout_icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 1.9rem;
  position: absolute;
  bottom: 5%;
}
.network_container {
  width: 100%;
  height: 90vh;
  background-color: white;
}
.network_first_box {
  width: 100%;
  height: 45%;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.network_button {
  font-size: 1rem;
  color: black;
  padding: 1.5% 2.3%;
  width: 100px;
  border: 1px solid #ebebeb;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  border-radius: 10px;
}
.network_button_active {
  font-size: 1rem;
  color: black;
  padding: 1.5% 2.3%;
  width: 100px;
  border: 1px solid #08152d;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border-radius: 10px;
}
.first_box_part1 {
  width: 100%;
  justify-content: space-around;
  height: 55%;
  display: flex;
  align-items: center;
}
.first_box_part2 {
  width: 100%;

  height: 45%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.first_box_part2_title {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
}

.text_depth {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: 'black';
  font-weight: 600;
}
.grayLinebox {
  padding: 10px 8%;
}
.network_second_box {
  width: 100%;
  height: 55%;
  overflow: scroll;
}
.network_valuations {
  color: #08152d;

  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  text-align: center;
  font-weight: 700;
}
.network_list_item {
  width: 100%;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #08152d;
}
.network_list_box {
  display: flex;
  padding: 30px 35px;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  justify-content: space-between;
}
.network_uparrow {
  width: 20px;
  height: 20px;
  margin: 35px auto;
  display: flex;
  transform: rotate(270deg);
}
.network_static_content {
  margin: 0 31px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #08152d;
  font-weight: bold;
}
.network_static_content_2 {
  text-align: center;

  margin: 10px 30px 0 30px;
  font-size: 15px;
  color: #08152d;
  font-weight: bold;
}
.here_button {
  font-size: 15px;
  color: #08152d;
  font-weight: bold;
  padding: 2px 8px;
  border: 1px solid #08152d;
  margin: 0 5px;
}
.affiliate_container {
  width: 100%;
  height: 90vh;
  background-color: white;
  padding: 20px 30px 0 30px;
  position: relative;
}

.affiliate_head_container {
  display: flex;
  justify-content: space-between;
}
.affiliate_head_text {
  color: #08152d;

  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.withdraw_container {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  border: 1px solid #ebebeb;
}

.withdrawBalance {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.withdrawButton {
  padding: 5px 15px;
  border: 1px solid #08152d;
  color: #08152d;
  font-size: 11px;
}
.analyticsContainer {
  margin-top: 35px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #08152d;
}

.controlWrapContainer {
  display: flex;
  overflow: auto;
}

.controlContainer {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
  margin-right: 15px;
  height: 40px;
  min-width: 47.5%;
}
.controlIcon {
  width: 18px;
  height: 18px;

  background-size: contain;
}
.controlName {
  margin-left: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.733);
}
.latest_transaction {
  margin-top: 40px;
}
.latest_transaction_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.latest_transaction_text {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #08152d;
}
.latest_transaction_list {
  padding: 0;
  margin-top: 20px;
  border: 1px solid #ebebeb;
  display: flex;
  padding-bottom: 20px;
  height: 60%;
  display: flex;
  flex-direction: column;
}
.searchContainer {
  height: 40px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchInput {
  padding: 0 20px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}
.filter_item {
  display: flex;
  align-items: center;
  width: 60px;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #ebebeb;
}
.latest_transaction_content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.support_container {
  background-color: white;
  padding: 30px 20px 20px 20px;
  width: 100%;
  height: 90vh;
}
.support_header {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #08152d;
}
.support_subheader {
  margin: 2px 0 10px 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
.support_desc {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  margin-top: 10px;
  color: #08152d;
  line-height: 18px;
}
.support_list_item {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ebebeb;
}
.section_header {
  font-family: 'Montserrat', sans-serif;
  padding-top: 40px;
  color: #08152d;
}

.complan_container {
  width: 100%;
  height: 90vh;
  padding: 25px 30px 30px 30px;
  background-color: white;
}
.complan_subtitle {
  font-size: 1rem;
  color: #08152d;
}

.complan_header {
  font-size: 1.6rem;
  font-weight: 600;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.brand_item {
  height: 96px;
  width: 100%;
  color: #08152d;
  display: flex;
  align-items: center;
  margin: 12.5px 0px;
  padding-left: 23px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
}
.brand_title {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-left: 13.13px;
  color: #383c41;
}

.withdraw_section {
  width: 100%;

  background-color: #000000b3;
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
}
.withdraw_section_content {
  width: 100%;
  position: absolute;
  /* height: 65%; */
  height: auto;

  background-color: white;
  bottom: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.withdraw_section_top {
  width: 100%;
  position: absolute;
  height: 35%;
  background-color: transparent;
  top: 0;
}
.image_container {
  display: flex;
  justify-content: center;
}
.input_container {
  margin-top: 50px;
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.input_text {
  color: #041939;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}
.divider {
  background-color: #08152d;
  height: 1px;
  width: 100%;
  margin: 5px 0px;
}
.balance_container {
  display: flex;
  justify-content: space-between;
}
.balance_label {
  color: #001d41;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  margin-left: 10px;
  font-weight: 400;
}
.keyboard_container {
  background-color: #001d41;
  padding-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.keyboard_item_box {
  height: 72px;
  background-color: #001d41;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-self: center;
  align-items: center;
}
.confirm_withdraw_balance_button {
  height: 55px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001d41;
  font-size: 15px;
  font-weight: 700;
}

.withdraw_selection_title {
  color: #001d41;
  margin-top: 61px;
  margin-bottom: 34px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
}
.withdraw_selection_app_card {
  width: 100%;
  height: 74px;
  background-color: white;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding-left: 19px;
  border-radius: 15px;
  margin-bottom: 18px;
}
.withdraw_selection_apps_container {
  width: 100%;
  height: 100%;

  height: 39vh;
  overflow-y: scroll;
}
.withdraw_selection_app_card_title {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.complete_withdraw_title {
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-align: left;
  color: #001d41;
  margin-top: 10%;
}

.complete_withdraw_subtitle {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: left;
  color: #001d41;
  margin-top: 4%;
}
.complete_withdraw_sub_head {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: left;
  color: #001d41;
  margin-top: 4%;
}

.complete_withdraw_item_box {
  width: 100%;
  margin-top: 3%;
  height: 74px;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 18px;
}
.complete_withdraw_price {
  font-weight: 700;
  font-size: 15px;
  color: #001d41;
  font-family: 'Montserrat', sans-serif;
}
.complete_withdraw_close_button {
  width: 100%;
  height: 68px;
  background: #001d41;
  border: 1px solid #001d41;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 7% 0;
}
.withdraw_common {
  padding: 30px 30px 0 30px;
}
.loading_withdraw {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #001d41;
  position: absolute;
  bottom: 10%;
  text-align: center;
  width: 100%;
}
.itemContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemDetailsContainer {
  margin-left: 10px;
  width: 100%;
  height: 100%;
}
.itemImageContainer {
}
.txtname {
  color: #464b4e;
  font-family: 'Montserrat';
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 800;
}
.appname {
  color: #9ea1ad;
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: 600;
}
.center_text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  overflow: scroll;
}
.filtermenu_container {
  width: 100%;
  height: 100%;
}

.filter_card {
  border-bottom: 1px solid #ebebeb;
  padding: 40px 20px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #001d41;
  font-family: 'Montserrat';
  font-size: 14px;
  overflow: scroll;
}
/* .filter_card:last-child {
  border-bottom: none;
} */
.noT {
  color: #001d41;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
}
.searchList_container {
  width: 100%;
  height: 100%;
  margin-top: -20px;
}
.fullScreenHeader {
  margin-top: 40px;
}
.fullScreenTitle {
  color: #001d41;
  font-family: 'Montserrat';
  font-size: 17px;
  margin-right: 15px;
  font-weight: 700;
}

.back_button {
  padding: 8px 0;
  color: #001d41;
  font-family: 'Montserrat';
  text-decoration-line: underline;

  display: flex;
}

.searchBoxShadowContainer {
  display: flex;
  margin-top: 40px;
  height: 45px;
  padding: 0 15px;
  background-color: white;
  border-radius: 2px;
  align-items: center;
  box-shadow: 0 0 9px 12px rgb(221 214 214 / 17%);
}
.searchinput {
  font-family: 'Montserrat', sans-serif;
  padding: 0 15px;
}

.brokerearning_container {
  width: 100%;
  height: 90vh;
  overflow: scroll;
}
.broker_header {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #08152d;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 700;
}
.broker_subhead {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #08152d;
  font-size: 14px;
  margin-top: 10px;
}
.switchertext {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  padding-right: 15px;
  border-right: 1px solid #cacaca;
  font-weight: 600;
}
.brokerUser_container {
  display: flex;
  padding: 0 20px;
  align-items: center;
  margin-top: 10px;
  overflow: scroll;
  justify-content: center;
}
.horizontalItem {
  padding: 15px 5px;
  /* width: calc(100%-40px) / 4; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.bokertransactionList_container {
  height: auto;
  width: 100%;
  padding: 0 30px 10px 30px;
}
.btl_header_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: white;
}
.btl_stats {
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-size: 12px;
  text-decoration-line: underline;
}
.btl_header {
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
}
.btl_sectionList {
  width: 100%;
  height: 100%;
}
.transactiongroup {
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.transaction_item {
  display: flex;
  justify-content: space-between;

  background-color: white;
  padding: 15px 20px;
  margin: 0 5px;
  margin-bottom: 1px;
  box-shadow: 0 0 1px 3px rgba(15, 15, 15, 0.1);
  margin-top: 10px;
}
.transaction_txnName {
  color: #001d41;
  font-family: 'Montserrat', sans-serif;
  padding-right: 20px;
  font-size: 11px;
  font-weight: 600;
}
.txnCommination {
  font-size: 11px;
  color: #001d41;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.vertical_item {
  display: flex;
  flex-direction: row;
  padding: 25px 15px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
}
.nameContainer {
  margin-left: 10px;
  color: #001d41;
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.priceContainer {
  color: #001d41;
  opacity: 0.5;
  margin-bottom: 2px;
  text-align: right;
  margin-right: 10px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}
.userstats_container {
  width: 100%;

  background-color: #000000b3;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0%;
  transition: height 0.3s;
}
.userStats_close_button {
  background-color: #08152d;
  height: 45px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.userstatusBottomsheet_container {
  padding: 10px 15px;
}
.userstats_namecontainer {
  width: 100%;
  height: 100%;
  margin-left: 15px;
}
.userstats_name {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.userstats_email {
  color: #9a9a9a;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}
.userstats_listcontainer {
  margin: 20px 0;
  height: 100%;
  overflow: scroll;
}
.userstats_listitem {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 15px 20px;
  margin: 0 5px;
  margin-bottom: 1px;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0 0 1px 3px rgba(15, 15, 15, 0.1);
}
.userstats_listtitle {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.userstats_listvalue {
  color: #001d41;
  opacity: 0.5;
  font-size: 12px;

  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.carousel_item_container {
  border-radius: 6px;
  background-color: #08152d;
  justify-content: center;
  align-items: center;
  padding: 15px;
  /* margin: 0 1px; */
  display: flex;
  flex-direction: column;
  transform: scale(0.9);
  transition: scale 0.5s;
}
.carousel_item_container_active {
  transform: scale(1.2);
}
.minimizecarousel {
  width: 100%;
  display: flex;
  transition: height 0.3s;
}
.carousel_item_title {
  color: white;
  font-size: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5;
}
.carousel_item_value {
  color: white;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
}
.animate {
  display: '';
  transition: all 0.5s;
}

.councilOverride_header {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 30px;
  font-size: 18px;
  color: #08152d;
  font-weight: 600;
}
.brandcontroller_container {
  padding: 20px;
  width: 100%;
  height: 90vh;
}
.brandcontroller_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 25px;
}
.brandcontroller_icon_container {
  display: flex;
  height: 33.03px;
  align-items: center;
}
.brandcontroller_brand_title {
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: #383c41;
  margin-left: 8px;
  font-weight: 700;
}
.menuController_container {
  margin: 0 -20px;
  margin-top: 10px;
  border-bottom: 1px solid #e7e7e7;
  display: none;
}
.menuController_menuItem {
  padding: 0 30px;
  padding-bottom: 10px;
}
.menuItem_containor {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 7.5px 0;
  padding: 25px 20px;
  border: 1px solid #ebebeb;
  justify-content: space-between;
}
.menuItem_container_itemName {
  font-size: 18px;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.menuItem_container_itemSubText {
  font-size: 10px;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  opacity: 0.75;
}
.breadCrumbArrow {
  font-family: 'Montserrat', sans-serif;
  font-size: 12;
  font-weight: 400;
}
.fragment_container {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.prevBreadCrumb {
  font-size: 12px;
  color: #464b4e;
  font-family: 'Montserrat', sans-serif;
}
.currentBreadCrumb {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #464b4e;
  text-decoration-line: underline;
  font-weight: 600;
}
.feescreen_searchbar_container {
  height: 9.077vh;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
}
.feescreen_searchinput {
  height: 57px;
  font-size: 16px;
  color: black;
  font-family: 'Montserrat', sans-serif;
}
.btn_wrap {
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-left: 1px solid #ebebeb;
}
.exchangePairList_item_container {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  margin: 0 15px;
}
.pair_item_text {
  font-size: 18px;
  color: #08152d;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}
.exchangeActionModal_container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  background-color: #000000b3;
  transition: height 0.3s;
}
.eam_question {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-weight: 500;
}
.eam_pair {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-weight: 600;
}
.eam_btnAction {
  width: 100%;
  height: 70px;
  border: 1px solid #e5e5e5;
  padding: 0px 20px;
  margin: 9% 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
}
.plus_cross {
  position: 'absolute';
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;
}

.exchangeFee_inputwrap {
  width: 90%;
  height: 65px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-top: 10%;
  display: flex;
}
.input_text_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid #e5e5e5;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-weight: 600;
}
.exchangeFee_input {
  font-size: 18px;
  width: 100%;
  text-align: right;
  padding: 0px 30px;
}
.loading_title {
  font-size: 30px;
  margin-left: 2%;
  color: #08152d;
  font-weight: 600;
}
.updating_text {
  position: absolute;
  bottom: 0%;
  font-size: 17px;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.exchangebaseFooter_container {
  height: 10.6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  overflow: scroll;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.exf_title {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
  font-weight: 600;
}
.exf_item {
  height: 40px;
  min-width: 70px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.exf_item2 {
  height: 40px;
  min-width: 70px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-radius: 4px;
  margin-left: 10px;
  padding: 0 15px;
  color: #08152d;
  font-size: 16px;
}
.mma_container {
  position: absolute;
  bottom: -10vh;
  background-color: #000000b3;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
}
.mma_inner_container {
  width: 100%;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  padding: 40px;
  /* max-height: 500px; */
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mma_btnAction {
  width: 100%;
  height: 70px;
  border: 1px solid #e5e5e5;
  padding: 0px 20px;
  margin-bottom: 8%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #08152d;
}
.networklevelDetail_contianer {
  width: 100%;
  height: 90vh;
}
.networklevelDetail_coverContainer {
  display: flex;
  align-items: center;
  padding: 40px 30px;
}
.nld_title_container {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-end;
}
.nld_title {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  text-align: right;
}
.nld_valuation {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  margin-top: 5px;
  font-weight: 600;
  text-align: right;
}
.nld_levelHeader {
  height: 60px;
  border: 1px solid #ebebeb;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  font-size: 15px;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.nld_backButton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  padding: 22px 0;
  padding-left: 15px;
  display: flex;
}
.nld_controllerContainer {
  display: flex;
}
.nld_controllerPrimary {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  width: 100%;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 700;
}
.nld_controller {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
.nld_feature_container {
  display: flex;
  padding: 25px 0;
}

.nld_feature {
  display: flex;
  align-items: center;

  flex-direction: column;
  width: 100%;
}
.nld_feature_label {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
.nld_feature_value {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  margin-bottom: 5px;
  font-weight: 600;
}
.nld_list_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
  height: 40%;
  padding: 5% 0;
}
.nld_listItem {
  display: flex;
  justify-content: space-between;
  padding: 5px 35px;
  align-items: center;
}
.nld_listLabel {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
.nld_listValue {
  color: #08152d;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  opacity: 0.5;
  font-weight: 700;
}
