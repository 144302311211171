.app-registration {
  color: #08152d;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: white;
  .side-image {
    flex: 1;
    // width: none;
    height: 0;
    object-fit: cover;
  }
  .loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 9;
  }
  .step-sidebar-container {
    border-right: 1px solid $border-color;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    padding-left: 5rem;
    padding-right: 4rem;
    justify-content: space-evenly;
    .steps-header {
      font-weight: bold;
      font-size: 2.5rem;
    }
    .step-item {
      font-size: 18px;
      opacity: 0.3;
      display: flex;
      justify-content: space-between;
      &.active {
        font-weight: 600;
        opacity: 1;
      }

      .checked-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .registration-container {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .app-logo {
      width: 250px;
    }
    .view-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3rem;
      .sub-text {
        text-align: center;
      }
      .actions-container {
        margin-top: 50px;
        width: 400px;
        display: flex;
        flex-direction: column;
      }
      .form-buttons {
        display: flex;
        margin-top: 10px;
        .action-outlined {
          flex: 1;
          margin: 0 20px;
          padding: 10px 15px;
          font-size: 13px;
          border: 1px solid $border-color;
        }
        .action-filled {
          flex: 1;
          margin: 0 20px;
          padding: 10px 15px;
          font-size: 13px;
        }
      }

      .input-container {
        border: 1px solid $border-color;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
        width: 350px;
        margin: 0 auto;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
        .input-validator {
          width: 5px;
          background-color: #e45147;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          &.valid {
            background-color: green;
          }
        }
        .input-field {
          flex: 1;
          margin-right: 10px;
          background: transparent;
          border: none;
          padding: 15px 4px;
          color: $primary-color;
          &::placeholder {
            color: #878788;
          }
        }
        .input-postfix {
          border-left: 1px solid $border-color;
          height: 100%;
          align-items: center;
          display: flex;
          padding-left: 20px;
          padding-right: 5px;
          font-weight: 600;
          font-size: 13px;
        }
        .otp-container {
          display: flex;
          width: 100%;
          height: 40px;
          div {
            flex: 1;
            div {
              &:nth-child(1) {
                flex: 1;
              }
            }
          }
        }
        .otp-input {
          font-weight: bold;
          font-size: 2rem;
          border: none;
          background: #ffffff;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
        }
        .input-action {
          cursor: pointer;
          .input-action-icon {
            width: 20px;
          }
        }
      }
      .action-outlined {
        border: 1px solid $primary-color;
        font-weight: 500;
        padding: 12px 25px;
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;
        color: #08152d;
        white-space: nowrap;
      }
      .action-filled {
        background-color: $primary-color;
        color: white;
        font-weight: 500;
        padding: 12px 25px;
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;
        white-space: nowrap;
      }
      .broker-details {
        text-align: center;
        border: 1px solid $border-color;
        margin-top: 30px;
        margin-bottom: 10px;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 20px;
        .broker-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        .broker-name {
          font-weight: 600;
          font-size: 20px;
          text-transform: capitalize;
          margin-top: 10px;
        }
        .broker-email {
          font-size: 12px;
        }
        hr {
          color: $border-color;
          width: 100%;
        }
        .bio-header {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .broker-bio {
        }
      }
    }
    .no-broker-text {
      font-weight: 600;
      text-align: center;
      margin-top: 20px;
    }
  }
}

.password-requirements {
  width: 450px;
  .header {
    text-align: center;
    background-color: $primary-color;
    color: white;
    font-weight: 700;
    padding: 12px 0;
    font-size: 18px;
  }
  .modal-body {
    padding: 40px;
    .item {
      margin-bottom: 25px;
      font-weight: 500;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
