.installer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 0 10px;
  position: relative;
  .title {
    color: #08152d;
    font-weight: 600;
    font-size: 2rem;
    border-bottom: 4px solid #08152d;
    display: flex;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  .selection-item {
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    padding: 25px 30px;
    margin-top: 1.5rem;
    cursor: pointer;
    &.disabled {
      opacity: 0.3;
    }
    .item-icon {
      width: 25px;
      margin-right: 10px;
    }
    .item-name {
      flex: 1;
      color: #545454;
      font-size: 1rem;
    }
    .item-label {
      flex: 1;
      color: #545454;
      font-size: 0.9rem;
    }
    .item-link {
      flex: 1;
      color: #545454;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.8rem;
    }
    .copy-icon {
      width: 15px;
      margin-left: 10px;
    }
  }
  .home-button {
    background-color: #08152d;
    margin: 0 -30px;
    text-align: center;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
  }
}
