.AboutDashboard {
    display: flex;
    align-items: center;
    padding-top: 107px;
    height: 100vh;
    padding-left: 88px;
    padding-bottom: 96px;
    padding-right: 88px;

}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.iframe-video{
    height: 50vh;
    width: 60%; 

    border-radius: 10px;


}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-section{
    background: #08152D;
    height: 100vh;
    padding-right: 88px;
    padding-left: 88px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1{
        font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 45px;
/* or 150% */
margin-top: 2.5rem;
display: flex;
align-items: center;
flex-wrap: wrap;

img{
    margin-left: 12px;
    margin-right: 12px;
}
color: #FFFFFF;
    }
    h3{
        width: 647px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
  display: flex;

        align-items: center;
        text-align: center;
        
        color: #FFFFFF;
        
display: flex;
align-items: center;
text-align: center;
justify-content: center;
margin-bottom: 0;
color: #FFFFFF;
    }
}
.mobile-image{
    display: none;
}
.rightside-affilate-image {
    background-image: url("../../assets/images/about-affilate.svg")
}

.right_image {
    height: 100%;
    width: calc(100% - 46rem);
    display: flex;
    align-items: center;
    img {
        height: 84%;
        width: 100%;
    }
}

.Network {
    width: 46rem;
 h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 70px;
        line-height: 75px;
        margin-bottom: 0;
        color: #08152D;
    }
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 55px;
        display: flex;
        align-items: center;

        color: #08152D;
    }

    h4 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 100;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        color: #182542;
        cursor: pointer;

    }

    img {
        width: 30px;
        height: 30px;
        margin-left: 22px;
        cursor: pointer;

    }
}

.Contain-section-about {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 50%;
    transition: all 0.6s ease-in;

    &:hover {
        transition: all 0.6s ease-in;
    }
}

.bottom-section {
    display: flex;
    flex-wrap: wrap;
    width: 41rem;
    justify-content: space-between;
    margin-top: 6rem;

}

.bottomBanner {
    height: 96px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #08152D;
    padding-left: 88px;

    padding-right: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-area {
        width: 29.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .image-section {
        display: flex;
        align-items: center;
        position: relative;
        -webkit-animation: linear infinite;
        -webkit-animation-name: run;
        animation: linear infinite;
        -webkit-animation-duration: 1s;
        animation-name: run;
        animation-duration: 1s;

        img {
            height: 16px;
            width: 16px;
        }
    }

    @keyframes run {
        0% {
            left: 0;
        }

        50% {
            left: 10px;
        }

        100% {
            left: 0;
        }
    }

    .text-area {
        h6 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #FFFFFF;
            margin-bottom: 0;
        }
    }

    .store {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 19.5rem;


        .line-affilate {
            width: 1px;
            height: 40px;
            background-color: white;
        }

        .paystore {
            width: 123px;
            height: 31.27px;
            transition: all 0.6s ease-in;
            cursor: pointer;
            &:hover{
        transform: scale(1.1);
        transition: all 0.6s ease-in;
            }
        }
    }
}


.affilate-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 88px;
    padding-right: 88px;
    position: relative;
    background: #08152D;

    .list {
        height: 265px;

        padding-top: 39px;

        width: 32%;
        display: flex;
        flex-direction: column;

        padding-left: 3%;
        padding-right: 3%;
        background: #FFFFFF;
        border: 0.5px solid #EFEFEF;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        border-bottom: 11px solid #08152D;
        overflow: hidden;
        .letter {
            position: absolute;
            left: 0;
            right: 0;
            background-color: #08152D;
            height: 100px;
            border-radius: 50px;
            width: 100px;
            top: -22px;
            left: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: -1;
        }
        h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
            color: #fff;
            margin: 0px;
            padding-top: 10px;
        }
        h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 22px;
            line-height: 27px;
            margin-top: 47px;
            color: #08152D;
        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 25px;
            color: #182542;
            margin-bottom: 0;
        }
    }
}

.background-affilate {
    width: 100%;
    position: absolute;
    top: 0;
    background: #f1f1f1;
    z-index: 1;
    height: 138px;
    left: 0;
}

.affilate-about-dash {
    height: auto;
    padding-bottom: 6rem;
    position: relative;
    background: #08152D;
}

.affilate-about {
    background: #f1f1f1;
    height: 33rem;
    display: flex;
    padding-left: 88px;
    z-index: 1;
    padding-right: 88px;
    align-items: center;
    position: relative;
img{
    width: 50%;
}
    .right {
        padding-left: 7%;
    }

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 49px;
        color: #08152D;

    }

    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #182542;
    }
}

@media screen and (max-width: 900px) {
    .video-section{
        padding-left: 7%;
        padding-right: 7%;    
     h3{
        width: 100%;
     }
    }
    .bottomBanner 
    {
        height: 79px;
        padding-left: 7%;
        padding-right: 7%;
        .store {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    .text-area {
       display: none;
    }
}
    .right_image {
        height: 550px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        justify-content: center;
        img {
            height: 84%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -50%);
        }
    }
    .mobile-image{
        display: block;
    }
    .desktop-image{
display: none;
    }
    .affilate-about {
        background: #f1f1f1;
        height: auto;
        display: block;
        padding-left: 7%;
        z-index: 1;
        padding-right: 7%;
        align-items: center;
        position: relative;
        padding-top: 3rem;
        margin-bottom: 10px;
    }
    .AboutDashboard {
        height: auto;
        display: block;
        padding-bottom: 58px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .affilate-about-dash {
        height: auto;
        position: relative;
       
    }
    .background-affilate{
        display: none;
    }
    .bottomBanner {
        height: 79px;
        width: 100%;
        position: fixed;
        bottom: 0;
        background: #08152D;

        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 9;
    }
    .affilate-card {
    
        padding-left: 7%;
        padding-right: 7%;
     padding-bottom: 97px;
        flex-wrap: wrap;
        background: #08152D;
        .list{
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .affilate-about{
      p{
        font-size: 12px;
        line-height: 29px;
      }
        .right {
            padding-left: 0%;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        img{
            width: 100%;
        }
        h1 {
     
            font-weight: 800;
            font-size: 35px;
            line-height: 35px;
            color: #08152D;
        }
    }
    .bottom-section {
        width: 100%;
        margin-top: 3rem;
    }
    .Network {
        width: 100%;
        padding-left: 7%;
        padding-right: 7%;
   
        img {
            margin-left: 12px;
            width: 16px;
            height: 16px;
        }

        h1 {
            font-weight: 800;
            font-size: 30px;
            line-height: 35px;

        }

        h4 {
            font-size: 13px;
            white-space: nowrap;
            line-height: 16px;
        }

        h3 {
            font-size: 19px;
            line-height: 32px;
        }
    }
  
}
@media screen and (min-width: 1001px) and  (max-width: 1400px)  {
    .video-section{
        h3{
           width: 70%;
        }
       }
    .Network {
        width: 100%;
    }
    .right_image {
        height: 100%;
        width: 30%;
        display: none;
        align-items: center;
    }

    .affilate-about-dash {
    height: auto;
        padding-bottom: 7rem;
        position: relative;
    }
    .affilate-about {
        background: #E5E5E5;
        height: 28rem;
    }


}


@media screen and (min-width: 1600px) {
    .video-section{
        padding-left: 138px !important;
        padding-right: 138px;
       
       }
    .AboutDashboard {
        padding-left: 138px !important;
        padding-right: 138px;
    }

    .affilate-card {
        padding-left: 138px !important;
        padding-right: 138px;
    }

    .affilate-about {
        padding-left: 138px !important;
        padding-right: 138px;
    }

    .bottomBanner {
        padding-left: 138px !important;
        padding-right: 138px;
    }

    .right_image img {}
}