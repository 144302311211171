.hmepageBrands {
    height: 100vh;
    display: flex;
    padding-top: 109px;

    .inside-section {
        padding-left: 18px;
        display: flex;
        width: 100%;
        height: 63px;
        align-items: center;
        margin-bottom: 1rem;
cursor: pointer;
position: relative;
.arrow{
    position:absolute;
    right: 0;
width: 23px;
/* Group 12011 */

right: -10px;
top: 7px;
position: absolute;
width: 23px;
}
.logo-brands {
            width: 23.18px;
        }

        h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-bottom: 0;
            color: #212121;
        }
    }
}

.inline-image
{
    width: calc(100% - 126px);
}
.left-side {
    padding-left: 33px;
    //display: flex;
    border-right: 0.5px solid #EBEBEB;
    width: 396px;
    padding-top: 2rem;
    height: 100%;
    overflow: scroll;
    overflow-y: scroll;
    overflow-x: hidden;

}
::-webkit-scrollbar {
    display: none;
}

.right-side-dash{
    overflow: scroll;
    width: 100%;
    height: 100%;
}
.right-side {
 
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    grid-column-gap: 2%;
    column-gap: 2%;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 3rem;
   
 
    padding-top: 2rem;

    .cards {
        width: 32%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 3.5rem;

        .label-brands-section {
            display: flex;
            align-items: center;
            width: 10rem;
            margin-top: 12px;
            justify-content: space-between;

        }


        label {
            margin-bottom: o;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            text-align: center;
            margin-bottom: 0;
            width: 72.99px;
            height: 23.3px;

            background: #F1F1F1;
            border: 1px solid #F1F1F1;
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.47);
            &:hover{
                color: #fff;
                background-position: 100%;
                transform: translateX(0.5rem);
                background-color: rgb(8, 21, 45);
                transform: translateY(-3px);
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
            }
        }

        h1 {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            color: #182443;
          

        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 0;
            color: rgba(24, 36, 67, 0.47);
            margin-top: 3px;
        }

        img {
            width: 38.4px;

        }

        .imgdiv-brands {
            width: 85.71px;
            height: 85.71px;
            width: 85.71px;
            height: 85.71px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5%;
            color: rgba(24, 36, 67, 0.47);
            box-shadow: 1px  0px 4px 4px rgba(144, 140, 140, 0.1);
            border-radius: 10px;
            // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
        }

    }
}



@media screen and (min-width: 1600px) {
    .inside-section {



    }

    .left-side {
        width: 508px;
        height: 100vh;
    }
}