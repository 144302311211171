@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import 'responsive';
@import 'colors';
@import 'mixins';
@import 'antd-overrides';
@import 'crm';
@import 'gx-tokens';
@import 'login-page';
@import 'layout-with-sidebar';
@import 'side-nav';
@import 'chat';
@import 'loading';
@import 'tab-bar';
@import 'crm-fragment';
@import 'gx-tokens-content';
@import 'cover';
@import 'my-brokerage.scss';
@import 'search.scss';
@import 'main-controller';
@import 'training-controller';
@import 'marketplace-controller';
@import 'otc-desk-controller';
@import 'registration';
@import 'selectFileModal';
@import 'bot';
@import 'bots-transactions';
@import 'bot-action-add';
@import 'bot-fiat-funding';
@import 'wallet-screen';
@import 'deposit-modal';
@import 'earn-page';
@import 'licenses-bot';
@import 'checkout';
@import 'bot-admin-menu';
@import 'site-landing-page';
@import 'all-apps-page';
@import 'mobile-apps';
@import 'app-installer';
@import 'app-registration';
@import 'bot-network';
@import 'modals';
@import 'chain-view';
@import 'search-layout';
@import 'mobile-landing';
@import 'profile-page';
@import 'brands-page';
@import 'app-details-page.scss';
@import 'endoresments.scss';
@import 'endoresment-home.scss';
@import 'bank-page.scss';

body {
  margin: 0;
  font-family: 'Montserrat', 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: none;
  outline: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.fragment-root {
  flex-grow: 1;
  width: 0;
  display: flex;
  flex-direction: column;
}

.action-button {
  border: 1px solid $border-color;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: $primary-color;
  cursor: pointer;
  font-size: 1.1rem;
  @include hover-zoom(1.05);
  &.inverted {
    border: none;
    background-color: $primary-color;
    color: white;
    margin-left: 10px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.full-loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 99999999;
  .full-loading-logo {
    height: 65px;
    animation: leaves 0.75s infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
