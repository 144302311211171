$tableGBColor: #ffffff;
$textColor: #182132;

.table-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.crm-table-wrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: $tableGBColor;
  flex-grow: 1;
  margin-bottom: 1rem;
  .details-table-header {
    position: sticky;
    top: 0;
    background: $tableGBColor;
    padding-top: 0.5rem !important;
    font-weight: 700 !important;
    font-size: 0.85rem !important;
    padding-bottom: 0.5rem !important;
    &.scroll-shadow {
      border-radius: 2px;
      box-shadow: 0 1px 10px rgba(153, 153, 153, 0.267);
    }
  }
  .fullscreen-toggler {
    position: absolute;
    right: 10px;
    height: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    i {
      color: $textColor;
      &:hover {
        color: #125bb6;
      }
    }
  }

  .details-table-item {
    display: flex;
    flex-direction: row;
    padding: 1rem 2rem;
    color: $textColor;
    font-size: 0.75rem;
    justify-content: space-between;
    .user-name {
      font-size: 1.2rem;
    }
    .user-country-icon,
    .token-txn-country-icon {
      height: 30px;
      width: 30px;
    }
    .user-signup-date {
      font-size: 1.2rem;
    }
    .user-broker-name {
      font-size: 1.2rem;
    }

    .token-txn-name,
    .user-name {
      font-size: 1.6rem;
    }
    .token-txn-country-icon,
    .user-country-icon {
      height: 30px;
      width: 30px;
    }
    .token-txn-date,
    .user-signup-date {
      font-size: 1.5rem;
    }
    .token-txn-broker,
    .user-broker-name {
      font-size: 1.5rem;
    }
    .token-txn-commission {
      font-size: 1.6rem;
    }
    .token-txn-volume {
      font-size: 1.6rem;
    }
    .date-indirect-otc,
    .coin-indirect-otc,
    .broker-indirect-otc,
    .name-indirect-otc,
    .commission-indirect-otc {
      font-size: 1.3rem;
    }
  }
  .flexed-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
      margin-bottom: 0;
    }
  }
}

.gx-pagination-wrapper {
  margin-top: 10px;
  .page-link {
    background-color: $tableGBColor;
    color: $textColor;
    &:hover {
      color: $textColor;
    }
    &.active {
      background-color: #ffffff;
      color: #000000 !important;
    }
  }
}

.gx-pagination-wrapper .gx-pagination {
  justify-content: flex-start;
  margin-bottom: 0;
}
