.app-details-page-wrapper {
  flex: 1;
  display: flex;
  .app-switcher-container {
    display: flex;
    border-right: 1px solid $border-color;
    height: 100%;
    flex-direction: column;
    .scroll-container {
      display: flex;
      flex-direction: column;
      height: 0;
      flex-grow: 1;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .tooltip {
      font-weight: 600;
      font-family: 'Montserrat';
      border: 1px solid #343c5b !important;
      opacity: 1;
      border-radius: 0;
      font-size: 1rem;
    }
    .app-switch-item {
      // flex: 1;
      padding: 60px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
      @include hover-zoom(1.2);
      &.active {
        opacity: 1;
      }
      .app-icon {
        width: 35px;
        height: 35px;
      }
    }
  }
  .app-details-wrapper {
    flex: 1;
    overflow: hidden;
    display: flex;
    .app-details-container {
      display: flex;
      flex-direction: column;
      color: $primary-color;
      justify-content: center;
      padding-left: 120px;
      padding-right: 150px;
      flex: 1;
      .app-logo {
        width: 60%;
        margin-right: auto;
        margin-bottom: 20px;
      }
      .app-header {
        font-weight: 600;
        margin-bottom: 50px;
        font-size: 1.3rem;
      }
      .app-desc {
        font-weight: 300;
        margin-bottom: 50px;
        line-height: 2.5;
        width: 80%;
        font-size: 1rem;
      }
      .action-container {
        display: flex;
        .action-button {
          border: 1px solid $border-color;
          width: 220px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: $primary-color;
          cursor: pointer;
          font-size: 1.1rem;
          @include hover-zoom(1.05);
          &.inverted {
            border: none;
            background-color: $primary-color;
            color: white;
            margin-left: 10px;
          }
        }
      }
    }
    .cover-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .cover-img {
        height: 100vh;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }
}
