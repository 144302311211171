.broker-app-nav-wrapper {
  background: white;
  z-index: 2;
  height: 100%;
  // zoom: 0.85;
  width: 0;
  // overflow-x: hidden;
  transition: all 0.2s linear;
  border-right: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  &.open {
    flex: 0.15 0 48px;
    .broker-app-nav {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  &.closed {
    flex: 0.035 0 48px;
    .broker-app-nav {
      padding-left: 0;
      padding-right: 0;
      align-items: center;
    }
    .brand-wrapper {
      width: 100%;
      height: 50px;
      margin-top: 30px;
      padding: 0;
      justify-content: center;
      .brand-img {
        width: 50px;
        height: 50px;
      }
    }
    .broker-details {
      opacity: 0;
      height: 0;
    }
    .menu-group-wrapper {
      justify-content: center !important;
    }

    .account-actions {
      width: 100%;
      flex-grow: 0;
      .broker-nav {
        .menu-item {
          border: none;
          justify-content: center;
          padding-bottom: 30px;
        }
      }
    }
    .nav-wrapper {
      flex-grow: 1;
      height: 0;
      justify-content: center;
      margin: 2rem 0;
      .tooltip {
        font-weight: 600;
        font-family: 'Montserrat';
        border: 1px solid #343c5b !important;
        opacity: 1;
        border-radius: 0;
        font-size: 1rem;
      }
      .menu-group {
        padding: 45px 0;
        &:last-child {
          padding-bottom: 45px;
        }
      }
    }
  }

  // @include xxl {
  //   zoom: 1;
  // }
  .broker-app-nav {
    color: #010b1e;
    background: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .brand-wrapper {
    margin-top: 4rem;
    width: 100%;
    height: 95px;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;
    z-index: 999;
    display: flex;
    align-items: center;
    .expand-toggle {
      border: 1px solid $primary-color;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -10px;
      // bottom: 0;
      z-index: 99;
      background-color: white;
      cursor: pointer;
      @include hover-zoom(1.1);
      transition: all 0.2s linear;
      .arrow-icon {
        width: 10px;
        height: 10px;
      }
    }
    &.broker-logo {
      border-radius: 0px;
      height: auto;
      .brand-img {
        height: auto;
      }
    }
    .brand-img {
      width: 95px;
      height: 95px;
      border-radius: 50%;
      transition: all 400ms ease-in-out;
    }
  }
  .broker-details {
    margin-top: 20px;
    opacity: 1;
    transition: all 400ms ease-in-out;
    width: calc(100% - 19px);
    display: flex;
    flex-direction: column;
    .broker-name {
      color: #010b1e;
      font-weight: bold;
      font-size: 1.4rem;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .broker-valuation {
      color: #010b1e;
      font-size: 0.85rem;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
    }
  }
  .nav-wrapper {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 1rem;
    flex-grow: 1;
    height: 0;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
    .menu-group {
      padding-bottom: 40px;
      padding-top: 40px;
      position: relative;
      transition: all 400ms linear;
      text-decoration: none;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        .menu-group-icon {
          opacity: 1 !important;
        }
      }
      &:last-child {
        padding-bottom: 0;
        &::before {
          content: none;
        }
      }
      .menu-group-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.active {
          .menu-group-icon {
            opacity: 1;
          }
        }
        &:hover {
          .menu-group-icon {
            opacity: 1;
          }
        }
        .menu-group-title {
          color: #010b1e;
          font-weight: bold;
          margin-bottom: 0;
        }
        .menu-status-icons {
        }
        .menu-group-icon {
          width: 40px;
          height: 40px;
          opacity: 0.3;
          transition: all 200ms ease-in-out;
        }
      }

      .broker-nav {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        height: auto;
        transition: all 400ms linear;
        max-height: 0;
        overflow: hidden;
        a {
          text-decoration: none;
        }
        &.opened {
          max-height: 600px;
          margin-top: 20px;
        }
        .menu-item {
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.9rem;
          padding: 10px 15px;
          cursor: pointer;
          margin-bottom: 10px;
          color: #010b1e;
          span {
            margin-right: 20px;
          }
          &.active {
            color: #010b1e;
            font-weight: bold;
          }
          &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
          }
          &:hover {
            background: #3b4c644f;
            border-radius: 40px;
          }
        }
      }
    }
  }
  .account-actions {
    display: flex;
    flex-direction: column;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    .broker-nav {
      margin-top: auto;
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      background-color: white;
      justify-content: center;
      background-color: white;
      a {
        text-decoration: none;
      }
      .menu-item {
        width: 100%;
        color: $primary-color;
        font-size: 1.5rem;
        padding: 15px 25px;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-top: 1px solid $border-color;
        justify-content: center;
        overflow: hidden;
        .menu-group-title {
          margin-bottom: 0;
          font-weight: bold;
          margin-left: 10px;
          @include hover-zoom(1.1);
        }
        span {
          @include hover-zoom(1.1);
          .closed {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
