.admin-pin-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .broker-logo {
    height: 200px;
    margin-top: 100px;
  }
  .title {
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
    color: #08152d;
    font-size: 1.2rem;
  }
  .input-container {
    margin-top: 20px;
    .otp-container {
    }
    .input-field {
      border: 1px solid rgba(88, 87, 87, 0.25);
      border-radius: 5px;
      font-size: 2rem;
      color: #08152d;
      font-weight: 600;
      width: 50px !important;
    }
    .separator {
      width: 10px;
    }
  }
  .proceed-btn {
    color: white;
    width: 150px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    background-color: #08152d;
    margin-top: 30px;
    border-radius: 6px;
    font-weight: 700;
  }
}

.admin-users-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  .title {
    font-weight: bold;
    color: #08152d;
    font-size: 1.3rem;
  }
  .search-container {
    display: flex;
    border: 0.5px solid #e5e5e5;
    padding: 0 15px;
    margin-top: 20px;
    .search-input {
      flex: 1;
      padding-right: 15px;
      height: 40px;
    }
    .search-category {
      border-left: 0.5px solid #e5e5e5;
      padding-left: 15px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
  .list-container {
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #08152d;
    .user-item {
      display: flex;
      border: 0.5px solid #ebebeb;
      padding: 10px 15px;
      margin-bottom: 10px;
      align-items: center;
      cursor: pointer;
      .user-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .user-name {
        font-weight: 700;
      }
      .user-email {
        font-size: 0.7rem;
      }
    }
  }
}
